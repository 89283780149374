import moment from "moment";
import { useCallback, useEffect, useState } from "react";

import client from "../client";
import handleApiError from "../../hooks/handleApiError";

export default function useFetchCalendarById(id) {
  const [loading, setLoading] = useState(false);
  const [calendar, setCalendar] = useState(null);
  const [holidays, setHolidays] = useState([]);

  const request = useCallback(async (_id) => {
    setCalendar(null);
    setHolidays([]);

    try {
      setLoading(true);

      const res = await client.get(`/calendar/${_id}`);

      setCalendar(res.data?.data);
      formatCalendarDates(res.data?.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  useEffect(() => {
    if (id) request(id);
  }, [id, request]);

  function formatCalendarDates(_calendar) {
    if (!_calendar) return;

    let resumptionDate = moment(_calendar?.resumptionDate).toDate();
    let resumption = {
      id: "resumption",
      title: "Resumption",
      start: resumptionDate,
      end: resumptionDate,
      color: "#0AD0AA",
      allDay: true,
    };

    let closingDate = moment(_calendar?.closingDate).toDate();
    let close = {
      id: "close",
      title: "Close",
      start: closingDate,
      end: closingDate,
      color: "#F66257",
      allDay: true,
    };

    let vacationStartDate = moment(_calendar?.vacationStartDate).toDate();
    let vacationEndDate = moment(_calendar?.vacationEndDate).toDate();
    let vacation = {
      id: "vacation",
      title: "Vacation",
      start: vacationStartDate,
      end: vacationEndDate,
      color: "#F7C94D",
      allDay: true,
    };

    setHolidays([vacation, resumption, close]);

    return null;
  }

  return { calendar, holidays, loading, request };
}
