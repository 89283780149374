import { useCallback, useEffect, useState } from "react";
import handleApiError from "../../../hooks/handleApiError";
import client from "../../client";

export default function useFetchStudentAttendance(studentId, calendarId) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [overview, setOverview] = useState(null);

  const request = useCallback(async (_studentId, _calendarId) => {
    try {
      setLoading(true);

      const res = await client.get(
        `/attendance/student/${_studentId}/calendar/${_calendarId}`
      );
      setData(res.data?.data);
      setOverview(res.data?.overview);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  useEffect(() => {
    if (studentId && calendarId) request(studentId, calendarId);
  }, [request, studentId, calendarId]);

  return { data, loading, overview };
}
