import { useEffect, useState } from "react";
import { FiEdit3 } from "react-icons/fi";
import { IoClose } from "react-icons/io5";

import {
  getGrade,
  getTotalForScorecard,
} from "../../../../shared/utils/functions";
import Loader from "../../../../shared/components/Loader";
import EmptyTable from "../../../../shared/components/EmptyTable";
import subjectBookImage from "../../../../assets/images/subjectbook.png";

const ScorecardTable = ({
  data,
  loading,
  payload,
  setPayload,
  scorecardId,
  handleEditScorecard,
  handleShowSubjectModal,
}) => {
  function handleUpdatePayload(value, key, card) {
    const payloadIndex = payload?.findIndex(
      (item) => item?.scorecardId === card?.scorecardId
    );

    if (payloadIndex !== -1) {
      const foundItem = payload[payloadIndex];
      foundItem[key] = Number(value);
      payload[payloadIndex] = foundItem;

      setPayload([...payload]);
    } else {
      setPayload([
        ...payload,
        {
          ...card,
          [key]: Number(value),
        },
      ]);
    }
  }

  function handleReset(key, card) {
    const payloadIndex = payload?.findIndex(
      (item) => item?.scorecardId === card?.scorecardId
    );

    if (payloadIndex !== -1) {
      const item = data?.find(
        (item) => item?.scorecardId === card?.scorecardId
      );

      const foundItem = payload[payloadIndex];
      foundItem[key] = item?.[key];
      payload[payloadIndex] = foundItem;

      setPayload([...payload]);
    }
  }

  useEffect(() => {
    if (data?.length > 0) {
      const newArray = JSON.parse(JSON.stringify(data));

      setPayload(newArray);
    }
  }, [data, setPayload]);

  const [hoverOne, setHoverOne] = useState({ index: -1, value: false });
  const [hoverTwo, setHoverTwo] = useState({ index: -1, value: false });
  const [hoverThree, setHoverThree] = useState({ index: -1, value: false });

  return (
    <div className="overflow-auto max-w-[90vw] min-h-[500px]">
      <div className="min-w-[700px]">
        {/* table header */}
        <div className="grid grid-cols-8 border-b">
          <div className="col-span-2 border-r p-3">
            <h5 className="font-semibold text-sm">Student Name</h5>
          </div>

          <div className="border-r p-3">
            <h5 className="font-semibold text-sm">C.A 1</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">C.A 2</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Exam</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Total</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Grade</h5>
          </div>

          <div className="p-3">
            <h5 className="font-semibold text-sm"> </h5>
          </div>
        </div>

        {loading ? <Loader loading={loading} /> : null}

        {!loading && !data?.length ? (
          <EmptyTable
            image={subjectBookImage}
            message={"No scorecard record found"}
            onClickMessage={"Select Subject"}
            onClick={handleShowSubjectModal}
          />
        ) : null}

        {/* Table body */}
        {payload?.map((card, i) => {
          return (
            <div
              className={`grid grid-cols-8 border-b hover:bg-primary_light_20 ${
                scorecardId === card?.scorecardId ? "active_table_row" : ""
              }`}
              key={i}
            >
              <div className="col-span-2 border-r p-3">
                <p className="text-independence text-sm capitalize">
                  {card?.lastName} {card?.firstName}
                </p>
              </div>

              <div
                className="border-r py-1 px-2 flex items-center"
                onMouseOver={() => {
                  setHoverOne({ index: i, value: true });
                }}
                onMouseLeave={() => {
                  setHoverOne({ index: -1, value: false });
                }}
              >
                <div
                  className={`flex items-center overflow-hidden ${
                    hoverOne?.value && hoverOne.index === i
                      ? "border border-primary"
                      : ""
                  }`}
                >
                  <input
                    onChange={(e) => {
                      handleUpdatePayload(
                        e.target.value,
                        "continuousAssessmentOneScore",
                        card
                      );
                    }}
                    value={card?.continuousAssessmentOneScore || ""}
                    className="text-sm py-1 px-1 border-0 outline-0 bg-transparent flex-1 h-full text-light_slate_grey w-full"
                  />

                  {hoverOne?.value && hoverOne.index === i ? (
                    <button
                      className="flex items-center justify-center px-1"
                      onClick={() =>
                        handleReset("continuousAssessmentOneScore", card)
                      }
                    >
                      <IoClose className="text-coral_red text-base" />
                    </button>
                  ) : null}
                </div>
              </div>

              <div
                className="py-1 px-2 border-r flex items-center"
                onMouseOver={() => {
                  setHoverTwo({ index: i, value: true });
                }}
                onMouseLeave={() => {
                  setHoverTwo({ index: -1, value: false });
                }}
              >
                <div
                  className={`flex items-center overflow-hidden ${
                    hoverTwo?.value && hoverTwo.index === i
                      ? "border border-primary"
                      : ""
                  }`}
                >
                  <input
                    onChange={(e) => {
                      handleUpdatePayload(
                        e.target.value,
                        "continuousAssessmentTwoScore",
                        card
                      );
                    }}
                    value={card?.continuousAssessmentTwoScore || ""}
                    className="text-sm py-1 px-1 border-0 outline-0 bg-transparent flex-1 h-full text-light_slate_grey w-full"
                  />

                  {hoverTwo?.value && hoverTwo.index === i ? (
                    <button
                      className="flex items-center justify-center px-1"
                      onClick={() =>
                        handleReset("continuousAssessmentTwoScore", card)
                      }
                    >
                      <IoClose className="text-coral_red text-base" />
                    </button>
                  ) : null}
                </div>
              </div>

              <div
                className="py-1 px-2 border-r flex items-center"
                onMouseOver={() => {
                  setHoverThree({ index: i, value: true });
                }}
                onMouseLeave={() => {
                  setHoverThree({ index: -1, value: false });
                }}
              >
                <div
                  className={`flex items-center overflow-hidden ${
                    hoverThree?.value && hoverThree.index === i
                      ? "border border-primary"
                      : ""
                  }`}
                >
                  <input
                    onChange={(e) => {
                      handleUpdatePayload(e.target.value, "examScore", card);
                    }}
                    value={card?.examScore || ""}
                    className="text-sm py-1 px-1 border-0 outline-0 bg-transparent flex-1 h-full text-light_slate_grey w-full"
                  />

                  {hoverThree?.value && hoverThree.index === i ? (
                    <button
                      className="flex items-center justify-center px-1"
                      onClick={() => handleReset("examScore", card)}
                    >
                      <IoClose className="text-coral_red text-base" />
                    </button>
                  ) : null}
                </div>
              </div>

              <div className="p-3 border-r">
                <p className="text-independence text-sm">
                  {getTotalForScorecard(card)}
                </p>
              </div>

              <div className="p-3 border-r">
                <p className="text-independence text-sm">
                  {getGrade(getTotalForScorecard(card))}
                </p>
              </div>

              <div
                className="flex items-center pl-2"
                onClick={() => handleEditScorecard(card)}
              >
                <button className="table_icon">
                  <FiEdit3 />
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ScorecardTable;
