import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Logo from "../../../shared/components/Logo";
import authImage from "../../../assets/images/payment.png";
import FormInput from "../../../shared/components/form/FormInput";
import FormButton from "../../../shared/components/form/FormButton";
import useFetchPlans from "../../../shared/api/payment/useFetchPlans";
import { formatCurrency, handleToast } from "../../../shared/utils/functions";
import useInitializeSubscription from "../../../shared/api/payment/useInitializeSubscription";
import { BsArrowRight } from "react-icons/bs";

const ChoosePlan = () => {
  const { schoolId } = useParams();

  const [plan, setPlan] = useState(null);
  const [state, setState] = useState(null);
  const [taxAmount, setTaxAmount] = useState(0);
  const [subAmount, setSubAmount] = useState(0);
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [studentThreshold, setStudentThreshold] = useState(0);

  const { data: plans } = useFetchPlans(state);
  const { request, loading } = useInitializeSubscription();

  async function handleContinue() {
    if (!plan) return handleToast("Please select a plan", "warning");
    if (studentThreshold < 25)
      return handleToast("Please enter a minimum of 30", "warning");

    await request({
      planId: plan?.planId,
      studentThreshold,
      schoolId,
    });
  }

  useEffect(() => {
    if (!state) {
      setState(localStorage.getItem("state"));
    }
  }, [state]);

  useEffect(() => {
    setSubAmount(plan?.amount * studentThreshold);
    setDiscountedAmount(subAmount * 0.2);
    setTaxAmount((subAmount - discountedAmount) * 0.075);
    setTotalAmount(subAmount - discountedAmount + taxAmount);
  }, [plan, studentThreshold, subAmount, taxAmount, discountedAmount]);

  return (
    <div className="auth_page">
      <div className="auth_page_content_grid">
        <div className="auth_logo_wrapper">
          <Logo />
        </div>

        {/* <a
          href={"https://teacher.getmyfigures.com"}
          target="_blank"
          rel="noreferrer"
        >
          <button className="flex items-center justify-center rounded-[4px] bg-primary py-2 px-3 min-[960px]:py-2 xl:py-3">

          </button>
        </a> */}

        <div className="auth_page_content">
          <h2 className="auth_page_title mb-1">Choose a suitable plan</h2>
          <p className="text-sm mb-10 text-center text-slate_grey">
            Choose a suitable plan to get started quickly
          </p>

          {/**
            |--------------------------------------------------
            | Plans
            |--------------------------------------------------
            */}
          <div>
            {plans?.map((p, i) => (
              <div
                className={`border mb-5 py-2 px-3 rounded-sm border-greyX11 cursor-pointer ${
                  plan?.planId === p?.planId ? "border-primary shadow-md" : ""
                }`}
                key={i}
                onClick={() => setPlan(p)}
              >
                <div className="flex items-center justify-between mb-2 border-b pb-2">
                  <p className="text-sm font-bold mb-1 text-yankee_blue capitalize">
                    {p?.name} Plan
                  </p>
                  <p className="text-sm text-slate_grey">
                    ₦{p?.amount} / term / student
                  </p>
                </div>

                <div>
                  <p className="text-sm text-yankee_blue capitalize">
                    {p?.description}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {/**
          |--------------------------------------------------
          | Total student
          |--------------------------------------------------
          */}
          <div className="mb-3">
            <h3 className="text-yankee_blue font-semibold text-sm mb-2">
              Number Of Students
            </h3>

            {/**
            |--------------------------------------------------
            | Student size selections
            |--------------------------------------------------
            */}
            <div className="flex items-center justify-between">
              <button
                className={`py-1 px-3 rounded-sm border border-greyX11 ${
                  studentThreshold === 25 ? "border-primary shadow-sm" : ""
                }`}
                onClick={() => setStudentThreshold(25)}
              >
                <p
                  className={`text-sm text-independence ${
                    studentThreshold === 25 ? "text-primary shadow-sm" : ""
                  }`}
                >
                  up to 25
                </p>
              </button>

              <button
                className={`py-1 px-3 rounded-sm border border-greyX11 ${
                  studentThreshold === 50 ? "border-primary shadow-sm" : ""
                }`}
                onClick={() => setStudentThreshold(50)}
              >
                <p
                  className={`text-sm text-independence ${
                    studentThreshold === 50 ? "text-primary shadow-sm" : ""
                  }`}
                >
                  26 - 50
                </p>
              </button>

              <button
                className={`py-1 px-3 rounded-sm border border-greyX11 ${
                  studentThreshold === 75 ? "border-primary shadow-sm" : ""
                }`}
                onClick={() => setStudentThreshold(75)}
              >
                <p
                  className={`text-sm text-independence ${
                    studentThreshold === 75 ? "text-primary shadow-sm" : ""
                  }`}
                >
                  51 - 75
                </p>
              </button>

              <button
                className={`py-1 px-3 rounded-sm border border-greyX11 ${
                  studentThreshold === 100 ? "border-primary shadow-sm" : ""
                }`}
                onClick={() => setStudentThreshold(100)}
              >
                <p
                  className={`text-sm text-independence ${
                    studentThreshold === 100 ? "text-primary shadow-sm" : ""
                  }`}
                >
                  76 - 100
                </p>
              </button>
            </div>
          </div>

          {/**
            |--------------------------------------------------
            | Student size custom input
            |--------------------------------------------------
            */}
          <FormInput
            required
            className={"mb-1"}
            value={studentThreshold}
            placeholder={"Enter specific amount"}
            onChange={(e) => setStudentThreshold(Number(e.target.value) || 0)}
          />
          <p className="text-xs text-slate_grey mb-5">
            This is the number of students that you can register in your school
          </p>

          {/**
            |--------------------------------------------------
            | Submit button
            |--------------------------------------------------
            */}
          <FormButton
            title={"Continue"}
            className="mb-0"
            type="submit"
            loading={loading}
            onClick={handleContinue}
          />
          <div
            className="text-yankee_blue text-sm mt-4 cursor-pointer flex justify-center items-center gap-5"
            onClick={() =>
              window.open(
                "https://www.getmyfigures.com/products/sms#pricing",
                "_blank"
              )
            }
          >
            <span className="">Visit our plan page for more information</span>
            <BsArrowRight />
          </div>

          {/**
            |--------------------------------------------------
            | Price breakdown
            |--------------------------------------------------
            */}
          {plan && studentThreshold ? (
            <div className="mt-5">
              <div className="flex items-center justify-between py-2">
                <p className="text-sm text-greyX11">Price</p>

                <h4 className="text-yankee_blue font-bold text-sm capitalize">
                  ₦{formatCurrency(plan?.amount)}
                </h4>
              </div>

              <div className="flex items-center justify-between py-2">
                <p className="text-sm text-greyX11">Total Students</p>

                <h4 className="text-yankee_blue font-bold text-sm">
                  x{studentThreshold}
                </h4>
              </div>

              <div className="flex items-center justify-between py-2 border-b">
                <p className="text-sm text-greyX11">Subamount</p>

                <h4 className="text-yankee_blue font-bold text-sm capitalize">
                  ₦{formatCurrency(subAmount)}
                </h4>
              </div>

              <div className="flex items-center justify-between py-2">
                <p className="text-sm text-greyX11">Discount (20%)</p>

                <h4 className="text-yankee_blue font-bold text-sm capitalize">
                  {formatCurrency(discountedAmount)}
                </h4>
              </div>

              <div className="flex items-center justify-between py-2">
                <p className="text-sm text-greyX11">VAT</p>

                <h4 className="text-yankee_blue font-bold text-sm capitalize">
                  (7.5%) ₦{formatCurrency(taxAmount)}
                </h4>
              </div>

              <div className="flex items-center justify-between py-2 border-t border-b">
                <p className="text-sm text-greyX11">Total Amount</p>

                <h4 className="text-yankee_blue font-bold text-sm capitalize">
                  <span className="line-through text-greyX11 mr-2">
                    ₦{formatCurrency(subAmount + taxAmount)}
                  </span>
                  ₦{formatCurrency(totalAmount)}{" "}
                </h4>
              </div>

              <p className="text-sm mt-3 text-congo_pink">
                Subsequently renews based on the number of students per term
              </p>
            </div>
          ) : null}
        </div>
      </div>

      <div className="auth_page_image_grid">
        <div className="auth_image_container">
          <img
            src={authImage}
            alt="GMF"
            className="block w-full h-full object-cover"
          />
        </div>
      </div>
    </div>
  );
};
export default ChoosePlan;
