import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  selectCalendar,
  selectSchoolDetails,
} from "../../../redux/slices/school/selectors";
import { handleToast } from "../../../shared/utils/functions";

import Loader from "../../../shared/components/Loader";
import PageLoader from "../../../shared/components/PageLoader";
import EmptyTable from "../../../shared/components/EmptyTable";
import SideModal from "../../../shared/components/general/SideModal";
import SelectInput from "../../../shared/components/form/SelectInput";
import PageActions from "../../../shared/components/general/PageActions";
import useFetchCalendars from "../../../shared/api/calendar/useFetchCalendars";
import useFetchClassDetails from "../../../shared/api/classes/useFetchClassDetails";
import useFetchStudentBasicInfo from "../../../shared/api/hooks/useFetchStudentBasicInfo";
import useFetchStudentReportCard from "../../../shared/api/cards/useFetchStudentReportCard";
import NewScorecard from "../../../shared/components/classes/studentReportCard/NewScorecard";
import useFetchAcademicSessions from "../../../shared/api/archives/useFetchAcademicSessions";
import NewReportCard from "../../../shared/components/classes/studentReportCard/NewReportCard";
import DeleteScorecard from "../../../shared/components/classes/studentReportCard/DeleteScorecard";
import PrincipalRemark from "../../../shared/components/classes/studentReportCard/PrincipalRemark";
import BehavourialSkill from "../../../shared/components/classes/studentReportCard/BehavourialSkill";
import UpdateStudentClass from "../../../shared/components/classes/studentReportCard/UpdateStudentClass";
import StudentClassHistory from "../../../shared/components/classes/studentReportCard/StudentClassHistory";
import useFetchStudentClassHistory from "../../../shared/api/archives/students/useFetchStudentClassHistory";
import useUpdateStudentClassHistory from "../../../shared/api/archives/students/useUpdateStudentClassHistory";
import ScorecardsByTermTableBody from "../../../shared/components/classes/studentReportCard/ScorecardsByTermTableBody";
import ScorecardsByTermTableHeader from "../../../shared/components/classes/studentReportCard/ScorecardsByTermTableHeader";
import ScorecardsByTermTableOverview from "../../../shared/components/classes/studentReportCard/ScorecardsByTermTableOverview";

// TODO: Add remark preview to page, add grade coloring
const StudentReportCard = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const term = query.get("term");
  const action = query.get("action");
  const session = query.get("session");
  const scorecardId = query.get("scorecardId");
  const markStudentAs = query.get("markStudentAs");

  const navigate = useNavigate();
  const school = useSelector(selectSchoolDetails);
  const currentCalendar = useSelector(selectCalendar);
  const { classId, schoolId, studentId } = useParams();

  const { data: academicSessions } = useFetchAcademicSessions();

  const { loading: studentDetailsLoading, data: studentDetails } =
    useFetchStudentBasicInfo(studentId);

  const { loading: classDetailsLoading, data: classsDetails } =
    useFetchClassDetails(classId);

  const { data: calendars } = useFetchCalendars(school?.state);

  const {
    reportCard,
    scorecards,
    loading: reportCardLoading,
    request: fetchReportCard,
  } = useFetchStudentReportCard(session, term);

  const { request: updateStudentClassRequest, loading: updating } =
    useUpdateStudentClassHistory();

  const {
    data: classHistories,
    loading: loadingClassHistories,
    request: fetchClassHistory,
  } = useFetchStudentClassHistory();

  const [selectedCalendar, setSelectedCalendar] = useState(null);

  useEffect(() => {
    if (classsDetails && selectedCalendar) {
      fetchClassHistory({
        studentId,
        classId: classsDetails?.classId,
        session: selectedCalendar?.session,
      });
    }
  }, [fetchClassHistory, selectedCalendar, classsDetails, studentId]);

  useEffect(() => {
    if (session === "null" && term === "null") {
      navigate(
        `/${schoolId}/classes/${classId}/students/${studentId}/reportcard?session=${currentCalendar?.session}&term=${currentCalendar?.term}`
      );
    }
  }, [
    session,
    term,
    navigate,
    currentCalendar?.session,
    currentCalendar?.term,
    classId,
    schoolId,
    studentId,
  ]);

  async function updateStudentClassHistory() {
    const payload = {
      status: markStudentAs,
      studentClassHistoryId: classHistories?.[0]?.studentClassHistoryId,
      classId,
      studentId,
    };
    if (markStudentAs === "left") payload.term = currentCalendar?.term;

    const isSuccesful = await updateStudentClassRequest(payload);
    if (isSuccesful) {
      navigate(`/${schoolId}/classes/${classId}/reportcards`);
    }
  }

  function closeSideModal() {
    navigate(
      `/${schoolId}/classes/${classId}/students/${studentId}/reportcard?session=${session}&term=${term}`
    );
  }

  function handleDeleteScorecard(scorecard) {
    navigate(
      `/${schoolId}/classes/${classId}/students/${studentId}/reportcard?scorecardId=${scorecard?.scorecardId}&action=delete&session=${session}&term=${term}`
    );
  }

  function handleCancelNewReportcard() {
    navigate(
      `/${schoolId}/classes/${classId}/students/${studentId}/reportcard?session=${session}&term=${term}`
    );
  }

  function handleSelectTerm(_term) {
    navigate(
      `/${schoolId}/classes/${classId}/students/${studentId}/reportcard?session=${session}&term=${_term}`
    );
  }

  function handleSelectSession(_session) {
    navigate(
      `/${schoolId}/classes/${classId}/students/${studentId}/reportcard?session=${_session}&term=${term}`
    );
  }

  useEffect(() => {
    if (
      term &&
      term !== "null" &&
      session &&
      session !== "null" &&
      calendars?.length
    ) {
      const found = calendars?.find(
        (el) => el?.session === session && el?.term === term
      );

      if (!found) {
        // setSelectedCalendar(null);
        return handleToast("Calendar record not found", "warning");
      }

      setSelectedCalendar(found);
    }
  }, [term, session, calendars]);

  if (studentDetailsLoading || classDetailsLoading)
    return (
      <PageLoader loading={studentDetailsLoading || classDetailsLoading} />
    );

  return (
    <div className={action || markStudentAs ? "page" : "full_page"}>
      <div className="mb-5 flex items-center justify-between">
        <div className="page_title_container">
          <h1 className="page_title">
            {`${studentDetails?.lastName || ""} ${
              studentDetails?.firstName || ""
            }'s`}{" "}
            Report Card
          </h1>
        </div>

        <PageActions
          actions={getPageActions(
            schoolId,
            classId,
            studentId,
            session,
            term,
            classHistories?.[0],
            reportCard,
            navigate
          )}
        />
      </div>

      {!reportCardLoading ? (
        <>
          <div className="flex items-center gap-2 mb-2 justify-end">
            <SelectInput
              placeholder={"Session"}
              options={academicSessions}
              containerStyle={"border-0"}
              className="min-w-[110px] mb-0 bg-white ssm:min-w-[150px]"
              handleSelect={(e) => handleSelectSession(e.currentTarget.value)}
              optionKeyLabel="session"
              optionValueLabel="session"
              value={session || ""}
            />

            <SelectInput
              value={term || ""}
              placeholder={"Term"}
              containerStyle={"border-0"}
              className="min-w-[110px] mb-0 bg-white ssm:min-w-[150px]"
              options={[
                { name: "First Term", value: "first" },
                { name: "Second Term", value: "second" },
                { name: "Third Term", value: "third" },
              ]}
              handleSelect={(e) => handleSelectTerm(e.currentTarget.value)}
            />
          </div>

          <div className="main_content !pb-3">
            <ScorecardsByTermTableOverview
              obtainableScore={reportCard?.obtainableScore || 0}
              obtainedScore={reportCard?.obtainedScore || 0}
              average={`${reportCard?.average || 0}%`}
              reportCard={reportCard}
            />

            <div className="table_wrapper">
              <div className="min-w-[800px]">
                <ScorecardsByTermTableHeader />

                {reportCardLoading ? (
                  <Loader loading={reportCardLoading} />
                ) : null}

                {!reportCardLoading && !reportCard ? (
                  <div className="main_content">
                    <EmptyTable
                      message={"No report card record found"}
                      onClickMessage={"Create Report Card"}
                      onClick={
                        selectedCalendar?.term === term
                          ? () =>
                              navigate(
                                `/${schoolId}/classes/${classId}/students/${studentId}/reportcard?action=new-reportcard`
                              )
                          : null
                      }
                    />
                  </div>
                ) : (
                  <ScorecardsByTermTableBody
                    data={scorecards}
                    handleDeleteScorecard={handleDeleteScorecard}
                    scorecardId={scorecardId}
                  />
                )}
              </div>
            </div>
          </div>

          <StudentClassHistory
            classHistory={classHistories?.[0]}
            loadingClassHistories={loadingClassHistories}
          />
        </>
      ) : null}

      <SideModal
        isVisible={action || markStudentAs}
        closeSideModal={closeSideModal}
      >
        {action === "new-reportcard" ? (
          <NewReportCard
            action={action}
            studentId={studentId}
            student={studentDetails}
            closeSideModal={closeSideModal}
            fetchReportCard={fetchReportCard}
            category={classsDetails?.category}
            handleCancelNewReportcard={handleCancelNewReportcard}
          />
        ) : null}

        {action === "delete" ? (
          <DeleteScorecard
            action={action}
            scorecardId={scorecardId}
            fetchReportCard={fetchReportCard}
            handleCancelDeleteScorecard={closeSideModal}
          />
        ) : null}

        {action === "new-scorecard" ? (
          <NewScorecard
            action={action}
            reportCard={reportCard}
            fetchReportCard={fetchReportCard}
            category={classsDetails?.category}
            handleCancelNewScorecard={closeSideModal}
            studentDepartment={studentDetails?.department}
          />
        ) : null}

        {action === "principal-remark" ? (
          <PrincipalRemark
            action={action}
            reportCard={reportCard}
            fetchReportCard={fetchReportCard}
            closeSideModal={closeSideModal}
          />
        ) : null}

        <UpdateStudentClass
          updating={updating}
          markStudentAs={markStudentAs}
          handleCancel={closeSideModal}
          currentCalendar={currentCalendar}
          handleUpdate={updateStudentClassHistory}
          studentName={`${studentDetails?.lastName || ""} ${
            studentDetails?.firstName || ""
          }'s`}
        />

        {action === "behavourial-skill" ? (
          <BehavourialSkill
            action={action}
            reportCard={reportCard}
            closeSideModal={closeSideModal}
            fetchReportCard={fetchReportCard}
          />
        ) : null}
      </SideModal>
    </div>
  );
};

function getPageActions(
  schoolId,
  classId,
  studentId,
  session,
  term,
  history,
  reportCard,
  navigate
) {
  const isGraduatingStudent =
    reportCard?.category === "JSS3" || reportCard?.category === "SSS3";

  const actions = [
    {
      label: "Preview Card",
      onClick: () => {
        navigate(
          `/${schoolId}/classes/${classId}/students/${studentId}/reportcard/${reportCard?.reportCardId}?session=${session}&term=${term}`
        );
      },
    },
    {
      label: "Add Scorecard",
      onClick: () => {
        navigate(
          `/${schoolId}/classes/${classId}/students/${studentId}/reportcard?action=new-scorecard&session=${session}&term=${term}`
        );
      },
    },
    {
      label: "Principal Remark",
      onClick: () => {
        navigate(
          `/${schoolId}/classes/${classId}/students/${studentId}/reportcard?action=principal-remark&session=${session}&term=${term}`
        );
      },
    },
    {
      label: "Behavourial Skill",
      onClick: () => {
        navigate(
          `/${schoolId}/classes/${classId}/students/${studentId}/reportcard?action=behavourial-skill&session=${session}&term=${term}`
        );
      },
    },
  ];

  if (!history?.endedSessionAs) {
    actions.push({
      label: "Mark student as left",
      onClick: () => {
        navigate(
          `/${schoolId}/classes/${classId}/students/${studentId}/reportcard?markStudentAs=left`
        );
      },
    });
  }

  if (!history?.endedSessionAs && reportCard && isGraduatingStudent) {
    actions.push({
      label: "Mark student as graduated",
      onClick: () => {
        navigate(
          `/${schoolId}/classes/${classId}/students/${studentId}/reportcard?markStudentAs=graduated`
        );
      },
    });
  }

  if (!history?.endedSessionAs && reportCard && !isGraduatingStudent) {
    actions.push(
      {
        label: "Retain in current class",
        onClick: () => {
          navigate(
            `/${schoolId}/classes/${classId}/students/${studentId}/reportcard?markStudentAs=retained`
          );
        },
      },
      {
        label: "Promote to next class",
        onClick: () => {
          navigate(
            `/${schoolId}/classes/${classId}/students/${studentId}/reportcard?markStudentAs=promoted`
          );
        },
      }
    );
  }

  return actions;
}

export default StudentReportCard;
