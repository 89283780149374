import { useCallback, useState } from "react";

import client from "../client";
import { handleToast } from "../../utils/functions";
import handleApiError from "../../hooks/handleApiError";

export default function useMakePayment() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const request = useCallback(async (payload) => {
    try {
      setLoading(true);
      const response = await client.post("/payment/pay-subscription", payload);

      setData(response.data?.data);

      setLoading(false);

      if (response.data?.data?.authorization_url) {
        handleToast(response.data?.message);
        window.open(response.data?.data?.authorization_url, "_blank");
      } else {
        handleToast(response.data?.message);

        return true;
      }
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  return { request, loading, data };
}
