import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import FormButton from "../../form/FormButton";
import { formatDate, handleToast } from "../../../utils/functions";
import { selectCalendar } from "../../../../redux/slices/school/selectors";
import useCreateAttendanceSheet from "../../../api/classes/attendance/useCreateAttendanceSheet";

export default function NewAttendanceSheets({
  week,
  sheets,
  students,
  currentDate,
  closeSideModal,
  checkFutureDate,
  fetchClassAttendance,
}) {
  const { classId, schoolId } = useParams();
  const calendar = useSelector(selectCalendar);
  const [selectedStudents, setSelectedStudents] = useState([]);

  /**
    |--------------------------------------------------
    | Api hook to submit attendance
    |--------------------------------------------------
    */
  const { request: createAttendanceRequest, loading: submitting } =
    useCreateAttendanceSheet();

  function handleSelect(params) {
    if (submitting) return;

    const index = selectedStudents?.findIndex(
      (el) => el?.studentId === params?.studentId
    );

    const param = {
      studentId: params?.studentId,
    };

    if (index >= 0) {
      setSelectedStudents(
        selectedStudents?.filter((el) => el?.studentId !== params?.studentId)
      );
    } else {
      setSelectedStudents([...selectedStudents, param]);
    }
  }

  const data =
    students &&
    students?.map((student) => {
      const index = sheets?.findIndex(
        (sheet) => sheet?.studentId === student?.studentId
      );

      return {
        studentId: student?.studentId,
        firstName: student?.firstName,
        lastName: student?.lastName,
        index,
      };
    });

  const allSheetsCreated = data?.every((el) => el?.index >= 0);

  async function createAttendanceSheets() {
    if (!selectedStudents?.length)
      return handleToast("Attendance sheet is empty", "warning");

    if (checkFutureDate()) {
      return handleToast("Marking future attendance is restricted", "warning");
    }

    if (week <= 0) {
      return handleToast("Week cannot be less than 1", "warning");
    }

    const isSuccess = await createAttendanceRequest(classId, {
      week,
      schoolId,
      date: formatDate(currentDate),
      calendarId: calendar?.calendarId,
      students: selectedStudents,
    });

    if (isSuccess) {
      setSelectedStudents([]);
      await fetchClassAttendance(Number(week), calendar?.calendarId);
      closeSideModal();
    }
  }

  if (!students?.length && !sheets?.length) {
    return null;
  }

  return (
    <div className="bg-white pb-4 rounded-md">
      <h5 className="side_page_title !mb-1 capitalize">
        New Attendance Sheets
      </h5>

      <p className="text-sm text-independence mb-6">
        Create new attendance sheets for the following students
      </p>

      {allSheetsCreated ? (
        <p className="text-sm text-independence mb-8 font-semibold">
          Hurray! All students have attendance sheet for the week
        </p>
      ) : null}

      {data?.map((el, i) => {
        const index = selectedStudents?.findIndex(
          (item) => item?.studentId === el?.studentId
        );

        if (el?.index === -1) {
          return (
            <div
              key={i}
              className={`flex items-center px-3 mb-3 h-12 rounded-sm cursor-pointer ${
                index >= 0 ? "bg-primary" : "bg-cultured "
              }`}
              onClick={() => handleSelect(el)}
            >
              <p
                className={`text-sm font-semibold capitalize ${
                  index >= 0 ? "text-white" : "text-yankee_blue"
                }`}
              >
                {`${el?.lastName || ""} ${el?.firstName || ""}`}
              </p>
            </div>
          );
        }

        return null;
      })}

      {allSheetsCreated ? null : (
        <FormButton
          loading={submitting}
          title={"Create Sheets"}
          onClick={createAttendanceSheets}
          className={"mt-8"}
        />
      )}

      <p
        className="text-center text-coral_red mt-1 py-3 cursor-pointer"
        onClick={() => {
          setSelectedStudents([]);
          closeSideModal();
        }}
      >
        Cancel
      </p>
    </div>
  );
}
