import Loader from "../../Loader";
import EmptyTable from "../../EmptyTable";
import useGetReportCardActions from "../../../hooks/useGetReportCardActions";
import { getGradeColor } from "../../../utils/functions";

const ReportCardTable = ({ loading, data, handleSelect, handleMark }) => {
  const { getReportCardActions } = useGetReportCardActions(handleMark);

  return (
    <div className="overflow-auto max-w-[90vw] min-h-[500px]">
      <div className="min-w-[700px]">
        {/* table header */}
        <div className="grid grid-cols-8 border-b">
          <div className="col-span-2 border-r p-3">
            <h5 className="font-semibold text-sm">Student Name</h5>
          </div>

          <div className="border-r p-3">
            <h5 className="font-semibold text-sm">Obtainable Score</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Obtained Score</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Average</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Grade</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Started session as</h5>
          </div>

          <div className="p-3">
            <h5 className="font-semibold text-sm">Status</h5>
          </div>
        </div>

        {loading ? <Loader loading={loading} /> : null}

        {!loading && !data?.length ? (
          <EmptyTable
            // image={subjectBookImage}
            message={"No report card record found"}
          />
        ) : null}

        {/* Table body */}
        {data?.map((card, i) => {
          return (
            <div
              className="grid grid-cols-8 border-b cursor-pointer hover:bg-primary_light_20"
              key={i}
            >
              <div
                className="col-span-2 border-r p-3 underline cursor-pointer"
                onClick={() => handleSelect(card)}
              >
                <p className="text-independence text-sm capitalize">
                  {card?.lastName} {card?.firstName}
                </p>
              </div>

              <div className="border-r p-3">
                <p className="text-independence text-sm">
                  {card?.obtainableScore}
                </p>
              </div>

              <div className="p-3 border-r">
                <p className={`text-independence text-sm`}>
                  {card?.obtainedScore}
                </p>
              </div>

              <div className="p-3 border-r">
                <p className={`text-independence text-sm capitalize`}>
                  {`${card?.average}%`}
                </p>
              </div>

              <div className="p-3 border-r">
                <p
                  className={`text-dark_electric_blue text-sm ${getGradeColor(
                    card?.average
                  )}`}
                >
                  {card?.grade}
                </p>
              </div>

              <div className="p-3 border-r ">
                <p className="text-independence text-sm capitalize">
                  {card?.studentClassHistory?.startedSessionAs === "entry"
                    ? "New Entry"
                    : card?.studentClassHistory?.startedSessionAs}
                </p>
              </div>

              <div className="p-3">{getReportCardActions(card, i)}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ReportCardTable;
