import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { handleToast } from "../../../shared/utils/functions";
import SideModal from "../../../shared/components/general/SideModal";
import useFetchClassDetails from "../../../shared/api/classes/useFetchClassDetails";
import AcademicSessions from "../../../shared/components/archives/AcademicSessions";
import useFetchStudentBasicInfo from "../../../shared/api/hooks/useFetchStudentBasicInfo";
import UpdateStudentClass from "../../../shared/components/classes/studentReportCard/UpdateStudentClass";
import useFetchClassReportCards from "../../../shared/api/archives/reportCards/useFetchClassReportCards";
import ArchivedReportCardTable from "../../../shared/components/archives/reportCards/ArchivedReportCardTable";
import useUpdateStudentClassHistory from "../../../shared/api/archives/students/useUpdateStudentClassHistory";
import ArchivedReportCardOverview from "../../../shared/components/archives/reportCards/ArchivedReportCardOverview";

export default function ArchivedReportcards() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const term = query.get("term");
  const session = query.get("session");
  const markStudentAs = query.get("markStudentAs");
  const studentId = query.get("studentId");

  const navigate = useNavigate();
  const { classId, schoolId } = useParams();
  const { loading: classLoading, data: classDetails } =
    useFetchClassDetails(classId);
  const {
    data: reportCards,
    loading: loadingCards,
    request: getReportCards,
  } = useFetchClassReportCards(classId);

  const { request: updateStudentClassRequest, loading: updating } =
    useUpdateStudentClassHistory();

  const { loading: studentDetailsLoading, data: studentDetails } =
    useFetchStudentBasicInfo(studentId);

  const [selectedTerm, setSelectedTerm] = useState("first");
  const [studentReportCard, setStudentReportCard] = useState(null);

  useEffect(() => {
    if (studentId && reportCards?.length) {
      const found = reportCards?.find((el) => el?.studentId === studentId);
      setStudentReportCard(found);
    }
  }, [studentId, reportCards]);

  async function updateStudentClassHistory() {
    if (
      (studentReportCard?.category === "SSS3" ||
        studentReportCard?.category === "JSS3") &&
      studentReportCard?.term !== "second"
    ) {
      return handleToast(
        "Graduating student can only be marked on second term report card",
        "warning"
      );
    }

    if (
      studentReportCard?.category !== "SSS3" &&
      studentReportCard?.category !== "JSS3" &&
      studentReportCard?.term !== "third"
    ) {
      return handleToast(
        "Student can only be marked on third term report card",
        "warning"
      );
    }

    const payload = {
      classId,
      studentId,
      status: markStudentAs,
      studentClassHistoryId:
        studentReportCard?.studentClassHistory?.studentClassHistoryId,
    };

    if (markStudentAs === "left") payload.term = studentReportCard?.term;

    const isSuccesful = await updateStudentClassRequest(payload);
    if (isSuccesful) {
      await getReportCards(session, term);
      closeSideModal();
    }
  }

  function handleSelect(card) {
    navigate(
      `/${schoolId}/classes/${classId}/archives/reportcard/${card?.reportCardId}?session=${session}`
    );
  }

  function handleMark(status, studentId) {
    navigate(
      `/${schoolId}/classes/${classId}/archives/reportcard?studentId=${studentId}&markStudentAs=${status}&session=${session}&term=${term}`
    );
  }

  function handleSelectSession(params) {
    navigate(
      `/${schoolId}/classes/${classId}/archives/reportcard?session=${params?.session}`
    );
  }

  function closeSideModal() {
    setStudentReportCard(null);
    navigate(
      `/${schoolId}/classes/${classId}/archives/reportcard?session=${session}&term=${selectedTerm}`
    );
  }

  useEffect(() => {
    if (session) {
      getReportCards(session, selectedTerm);
    }
  }, [getReportCards, session, selectedTerm]);

  return (
    <div>
      <div className="full_page">
        <h1 className="page_title mb-5">
          Reportcards{" "}
          {classDetails
            ? `(${classDetails?.category} ${classDetails?.suffix})`
            : null}
        </h1>

        <AcademicSessions
          handleSelect={handleSelectSession}
          session={session}
        />

        <div className="main_content">
          <ArchivedReportCardOverview
            classDetails={classDetails}
            classLoading={classLoading}
            teacher={`${reportCards?.[0]?.teacher?.lastName || ""} ${
              reportCards?.[0]?.teacher?.firstName || ""
            }`}
            reportCardsLength={reportCards?.length}
            handleSelectCalendar={setSelectedTerm}
            selectedTerm={selectedTerm}
            session={session}
          />

          <ArchivedReportCardTable
            data={reportCards}
            loading={loadingCards}
            handleSelect={handleSelect}
            handleMark={handleMark}
          />

          {/* <Pagination setPage={setPage} pagination={pagination} /> */}
        </div>
      </div>

      <SideModal isVisible={markStudentAs} closeSideModal={closeSideModal}>
        <UpdateStudentClass
          updating={updating}
          markStudentAs={markStudentAs}
          handleCancel={closeSideModal}
          session={studentReportCard?.session || ""}
          handleUpdate={updateStudentClassHistory}
          studentDetailsLoading={studentDetailsLoading}
          studentName={`${studentDetails?.lastName || ""} ${
            studentDetails?.firstName || ""
          }'s`}
        />
      </SideModal>
    </div>
  );
}
