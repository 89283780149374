import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import SideModal from "../../../shared/components/general/SideModal";
import { selectCalendar } from "../../../redux/slices/school/selectors";
import Subjects from "../../../shared/components/classes/scorecards/Subjects";
import useFetchClassDetails from "../../../shared/api/classes/useFetchClassDetails";
import EditScorecard from "../../../shared/components/classes/scorecards/EditScorecard";
import ScorecardTable from "../../../shared/components/classes/scorecards/ScorecardTable";
import useFetchClassSubjects from "../../../shared/api/classes/subjects/useFetchClassSubjects";
import ScorecardOverview from "../../../shared/components/classes/scorecards/ScorecardOverview";
import useFetchScorecardsByClassSubjectId from "../../../shared/api/cards/useFetchScorecardsByClassSubjectId";
import useUpdateScorecards from "../../../shared/api/cards/useUpdateScorecards";
import { ClipLoader } from "react-spinners";

const Scorecards = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const action = query.get("action");
  const classSubject = query.get("classSubject");
  const scorecardId = query.get("scorecardId");

  const navigate = useNavigate();

  const { classId, schoolId } = useParams();
  const calendar = useSelector(selectCalendar);

  const { loading: classLoading, data: classDetails } =
    useFetchClassDetails(classId);
  const { loading: subjectLoading, data: subjects } =
    useFetchClassSubjects(classId);

  const [selectedSubject, setSelectedSubject] = useState(null);
  const {
    loading,
    data: scorecards,
    request: fetchSubjectScorecard,
  } = useFetchScorecardsByClassSubjectId(
    selectedSubject?.classSubjectId,
    calendar
  );

  const [payload, setPayload] = useState([]);
  const { request: submitScoreRequest, loading: submitting } =
    useUpdateScorecards();

  useEffect(() => {
    if (classSubject && subjects?.length) {
      const found = subjects?.find((el) => el?.classSubjectId === classSubject);
      setSelectedSubject(found);
    }
  }, [classSubject, subjects]);

  async function handleSubmitCards() {
    if (submitting) return;

    const cards = payload?.map((item) => ({
      examScore: item?.examScore,
      scorecardId: item?.scorecardId,
      continuousAssessmentOneScore: item?.continuousAssessmentOneScore,
      continuousAssessmentTwoScore: item?.continuousAssessmentTwoScore,
    }));

    const _payload = { cards };
    const isSuccessful = await submitScoreRequest(_payload);
    if (isSuccessful) {
      closeSideModal({ refresh: true });
    }
  }

  function handleSelectSubject(params) {
    navigate(
      `/${schoolId}/classes/${classId}/scorecards?classSubject=${params?.classSubjectId}`
    );
  }

  function handleShowSubjectModal() {
    navigate(`/${schoolId}/classes/${classId}/scorecards?action=show-subjects`);
  }

  function closeSideModal({ refresh }) {
    navigate(`/${schoolId}/classes/${classId}/scorecards`);
    if (refresh) fetchSubjectScorecard(classSubject, calendar);
  }

  function handleEditScorecard(card) {
    navigate(
      `/${schoolId}/classes/${classId}/scorecards?scorecardId=${card?.scorecardId}`
    );
  }

  return (
    <div className={scorecardId || action ? "page" : "full_page"}>
      <div className="mb-5 flex items-center justify-between">
        <div className="page_title_container">
          <h1 className="page_title">Scorecards</h1>

          <p className="page_subtitle">
            {classDetails
              ? `(${classDetails?.category} ${classDetails?.suffix})`
              : null}
          </p>
        </div>

        {payload?.length ? (
          <button
            className="flex items-center justify-center gap-1 bg-primary text-sm h-8 xxs:h-8 ssm:h-9 rounded-sm text-white px-3 min-w-[80px]"
            onClick={handleSubmitCards}
          >
            {submitting ? (
              <ClipLoader size={20} loading={submitting} color="white" />
            ) : (
              "Submit Cards"
            )}
          </button>
        ) : null}
      </div>

      <div className="main_content">
        <ScorecardOverview
          classDetails={classDetails}
          classLoading={classLoading}
          subjectLength={subjects?.length}
          selectedSubject={selectedSubject}
          scorecardLength={scorecards?.length}
          handleShowSubjectModal={handleShowSubjectModal}
        />

        <ScorecardTable
          payload={payload}
          data={scorecards}
          loading={loading}
          setPayload={setPayload}
          scorecardId={scorecardId}
          handleEditScorecard={handleEditScorecard}
          handleShowSubjectModal={handleShowSubjectModal}
        />
      </div>

      <SideModal
        closeSideModal={closeSideModal}
        isVisible={scorecardId || action}
      >
        <EditScorecard
          scorecardId={scorecardId}
          handleCancelEditScorecard={closeSideModal}
          fetchSubjectScorecard={fetchSubjectScorecard}
        />

        <Subjects
          action={action}
          subjects={subjects}
          closeSideModal={closeSideModal}
          subjectLoading={subjectLoading}
          selectedSubject={selectedSubject}
          handleSelectSubject={handleSelectSubject}
        />
      </SideModal>
    </div>
  );
};
export default Scorecards;
