import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import SideModal from "../../../shared/components/general/SideModal";
import AcademicSessions from "../../../shared/components/archives/AcademicSessions";
import ArchivedStudentTable from "../../../shared/components/archives/students/ArchivedStudentTable";
import useFetchArchivedStudents from "../../../shared/api/archives/students/useFetchArchivedStudents";
import PromoteStudentToSeniorClass from "../../../shared/components/archives/students/PromoteStudentToSeniorClass";

export default function GraduatedStudents() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const session = query.get("session");
  const selected = query.get("selected");

  const navigate = useNavigate();
  const { schoolId, category, classId } = useParams();
  const {
    data,
    loading,
    request: fetchArchivedStudent,
  } = useFetchArchivedStudents(session, "graduated");

  function handleSelectSession(params) {
    navigate(
      `/${schoolId}/classes/${classId}/archives/graduated-students/${category}?session=${params?.session}`
    );
  }

  function handlePromoteToSeniorClass(params) {
    navigate(
      `/${schoolId}/classes/${classId}/archives/graduated-students/${category}?session=${params?.session}&selected=${params?.studentClassHistoryId}`
    );
  }

  async function closeSideModal(params) {
    navigate(
      `/${schoolId}/classes/${classId}/archives/graduated-students/${category}?session=${session}`
    );

    if (params?.refresh) {
      await fetchArchivedStudent(session);
    }
  }

  return (
    <div className={selected ? "page" : "full_page"}>
      <h1 className="page_title mb-5">Graduated Students</h1>

      <AcademicSessions handleSelect={handleSelectSession} session={session} />

      <div className="main_content">
        <ArchivedStudentTable
          data={data}
          loading={loading}
          showActionColumn={category === "junior"}
          handlePromoteToSeniorClass={handlePromoteToSeniorClass}
        />
      </div>

      <SideModal closeSideModal={closeSideModal} isVisible={selected}>
        <PromoteStudentToSeniorClass
          selected={selected}
          closeSideModal={closeSideModal}
        />
      </SideModal>
    </div>
  );
}
