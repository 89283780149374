import bookImage from "../../assets/images/subjectbook.png";
import emptyCalendarImage from "../../assets/images/cuate.png";
import studentAvatar from "../../assets/images/studentavatar.png";
import studentCsvHeaderImage from "../../assets/images/student-csv-header.png";

const IMAGES = {
  bookImage,
  studentAvatar,
  emptyCalendarImage,
  studentCsvHeaderImage,
};

export default IMAGES;
