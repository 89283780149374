import React from "react";
import { FiUpload } from "react-icons/fi";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { IoMdInformationCircle } from "react-icons/io";

import IMAGES from "../../../constant/images";

export default function UploadFileInstruction({
  file,
  handleSelectFile,
  showInstruction,
}) {
  const navigate = useNavigate();

  return (
    <div className="px-3">
      <button
        className="py-1 px-1 flex items-center gap-2 text-yankee_blue hover:bg-primary_light_30 hover:text-primary"
        onClick={() => navigate(-1)}
      >
        <BiArrowBack className="text-sm" />
        <small>Go back</small>
      </button>

      <h3 className="text-black-text font-semibold mb-5 mt-2">
        Upload students CSV file
      </h3>

      <div
        className="border flex items-center justify-center flex-col py-5 rounded-sm border-dashed max-w-lg cursor-pointer mb-5"
        onClick={handleSelectFile}
      >
        <FiUpload />

        <p className="text-lg text-primary font-medium mt-3">
          Click to upload file
        </p>
        <p className="text-sm text-davys_Grey font-light">
          Excel files only (CSV, XLSX)
        </p>
        {file ? (
          <p className="text-sm text-davys_Grey font-light">{file?.name}</p>
        ) : null}
      </div>

      {showInstruction ? (
        <div className="">
          <h3 className="text-black-text font-semibold mb-3">
            Upload instructions
          </h3>

          <div className="flex items-center gap-2 mb-3">
            <IoMdInformationCircle className="text-saffron" />

            <div>
              <p className="text-sm text-davys_Grey font-light">
                File must be a csv or xlsx file
              </p>
            </div>
          </div>

          <div className="flex items-center gap-2 mb-3">
            <IoMdInformationCircle className="text-saffron" />

            <div>
              <p className="text-sm text-davys_Grey font-light">
                File must have 9 columns
              </p>
            </div>
          </div>

          <div className="flex items-start gap-2 mb-1">
            <IoMdInformationCircle className="text-saffron" />

            <div>
              <p className="text-sm text-davys_Grey font-light mb-1">
                File must have the following headers as the first row:
              </p>
            </div>
          </div>

          <div className="overflow-auto max-w-full mb-3">
            <div className="min-w-[800px]">
              <div className="w-full overflow-scroll min-w-[600px]">
                <img
                  src={IMAGES.studentCsvHeaderImage}
                  alt=""
                  className="w-full object-contain"
                />
              </div>
            </div>
          </div>

          <div className="flex items-center gap-2 mb-3">
            <IoMdInformationCircle className="text-saffron" />

            <div>
              <p className="text-sm text-davys_Grey font-light">
                Date of birth should follow the format YYYY-MM-DD
              </p>
            </div>
          </div>

          <div className="flex items-center gap-2 mb-3">
            <IoMdInformationCircle className="text-saffron" />

            <div>
              <p className="text-sm text-davys_Grey font-light">
                Cell data must not have a comma character (,)
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
