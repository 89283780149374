import React from "react";
import { AiOutlineCheck } from "react-icons/ai";

const gradeScale = [
  { label: "Excellent", grade: "A1", range: "75% - 100%" },
  {
    label: "Very Good",
    grade: "B2",
    range: "70% - 74.99%",
  },
  { label: "Good", grade: "B3", range: "65% - 69.99%" },
  { label: "Credit", grade: "C4", range: "60% - 64.99%" },
  { label: "Credit", grade: "C5", range: "55% - 59.99%" },
  { label: "Credit", grade: "C6", range: "50% - 54.99%" },
  { label: "Pass", grade: "D7", range: "45% - 49.99%" },
  { label: "Fail", grade: "F9", range: "Below 40%" },
  { label: "", grade: "", range: "" },
];

// h-[1122px]
export default class ReportCard extends React.PureComponent {
  /**
	|--------------------------------------------------
	| Initiates the otp call
	|--------------------------------------------------
	*/
  render() {
    return (
      <div id="reportCard" className="w-[794px] px-4 py-10 flex flex-col">
        {/**
        |--------------------------------------------------
        | logo and school information
        |--------------------------------------------------
        */}
        <div className="flex">
          <div className="w-24 h-24">
            <img
              src={this.props.school?.logo}
              alt=""
              className="object-contain w-full h-full"
            />
          </div>

          <div className="flex-1">
            <h1 className="text-center text-gray-text font-bold mb-2 text-2xl capitalize">
              {this.props.school?.name}
            </h1>

            <p className="text-center text-gray-text text-xs capitalize">
              {this.props.school?.address}, {this.props.school?.lga},{" "}
              {this.props.school?.state} state
            </p>

            <div className="flex items-center justify-center gap-4 mb-1">
              <p className="text-center text-gray-text text-xs">
                Tel: {this.props.school?.phone}, {this.props.school?.phone2}
              </p>
              <p className="text-center text-gray-text text-xs">
                <span className="mr-1">Email:</span>
                {this.props.school?.email}
              </p>
            </div>

            <h2 className="underline text-gray-text text-center font-semibold uppercase">
              {this.props?.reportCard?.term} TERM STUDENT'S PERFOMANCE REPORT
            </h2>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-2 mt-5">
          {/**
          |--------------------------------------------------
          | column 1
          |--------------------------------------------------
          */}
          <div className="col-span-2">
            {/**
            |--------------------------------------------------
            | student information
            |--------------------------------------------------
            */}
            <div className="px-1 pt-2 border mb-4">
              <div className="flex justify-between items-center gap-5 mb-2">
                <div className="flex-1 flex gap-1">
                  <span className="text-xs text-gray-text font-bold">
                    NAME:
                  </span>
                  <p className="text-xs text-gray-text uppercase font-light border-b pb-[6px] flex-1 px-1">
                    {this.props?.reportCard?.lastName}{" "}
                    {this.props?.reportCard?.firstName}
                  </p>
                </div>
              </div>

              <div className="flex justify-between items-center gap-3 mb-2">
                <div className="flex-1 flex gap-1">
                  <span className="text-xs text-gray-text font-bold">
                    CLASS:
                  </span>{" "}
                  <p className="text-xs text-gray-text font-light pb-[6px] px-1 flex-1 border-b">
                    {this.props?.reportCard?.category}{" "}
                    {this.props?.reportCard?.suffix}
                  </p>
                </div>

                <div className="flex-1 flex gap-1">
                  <span className="text-xs text-gray-text font-bold">
                    SESSION:
                  </span>
                  <p className="text-xs text-gray-text font-light pb-[6px] px-1 flex-1 border-b">
                    {this.props?.reportCard?.session}
                  </p>
                </div>

                <div className="flex-1 flex gap-1">
                  <span className="text-xs text-gray-text font-bold">
                    GENDER:
                  </span>
                  <p className="text-xs text-gray-text font-light pb-[6px] px-1 flex-1 border-b">
                    Male
                  </p>
                </div>
              </div>

              {/* <div className="flex justify-between items-center mb-1">
                <div className="flex-1 flex gap-1">
                  <span className="text-xs text-gray-text font-bold">
                    CLUB/SOCIETY:
                  </span>
                  <p className="text-xs text-gray-text uppercase font-light pb-[6px] flex-1 px-1">
                    {this.props?.reportCard?.lastName}{" "}
                    {this.props?.reportCard?.firstName}
                  </p>
                </div>
              </div> */}
            </div>

            {/**
            |--------------------------------------------------
            | scorecards
            |--------------------------------------------------
            */}
            <div className="border mb-4">
              <div className="grid grid-cols-11 h-fit bg-gray-light border-b">
                <h4 className="border-r px-1 pt-5 pb-1 col-span-4 uppercase text-xs flex flex-col items-center justify-end font-bold text-gray-text">
                  subjects
                </h4>

                <div className="border-r px-1 pt-5 pb-1 flex flex-col items-center justify-end text-gray-text">
                  <span className="text-xs font-bold text-gray-text">C.A</span>
                  <p className="text-xs mt-0 font-bold text-gray-text">40</p>
                </div>

                <div className="border-r px-1 pt-5 pb-1 flex flex-col items-center justify-end">
                  <p className="text-xs -rotate-90 w-fit uppercase font-bold text-gray-text">
                    exam
                  </p>
                  <p className="text-xs mt-4 font-bold text-gray-text">60</p>
                </div>

                <div className="border-r px-1 pt-5 pb-1 flex flex-col items-center justify-end">
                  <p className="text-xs -rotate-90 w-fit uppercase font-bold text-gray-text">
                    Total
                  </p>
                  <p className="text-xs mt-4 font-bold text-gray-text">100</p>
                </div>

                <div className="border-r px-1 pt-5 pb-1 flex flex-col items-center justify-end">
                  <p className="text-xs -rotate-90 w-fit uppercase font-bold text-gray-text">
                    grade
                  </p>
                  <p className="text-xs mt-4 text-gray-text"></p>
                </div>

                <h4 className="col-span-2 uppercase px-1 pt-5 pb-1 text-xs flex flex-col items-center justify-end font-bold text-gray-text border-r">
                  Remarks
                </h4>

                <div className="px-1 pt-5 pb-1 flex flex-col items-center justify-end">
                  <p className="text-xs -rotate-90 w-fit uppercase font-bold">
                    Term
                  </p>
                  <p className="text-xs -rotate-90 w-fit font-bold mt-3">1st</p>
                  <p className="text-xs mt-1 font-bold">100</p>
                </div>
              </div>

              {this.props?.subjects?.map((sub, i) => {
                return (
                  <div
                    className="grid grid-cols-11 border-b last:border-b-0"
                    key={i}
                  >
                    <p className="col-span-4 border-r py-1 text-xs px-1 font-bold capitalize text-gray-text">
                      {sub?.name}
                    </p>

                    <p className="border-r py-1 text-xs flex items-center justify-center font-light text-gray-text">
                      {(Number(sub?.continuousAssessmentOneScore) || 0) +
                        (Number(sub?.continuousAssessmentTwoScore) || 0)}
                    </p>

                    <p className="border-r py-1 text-xs flex items-center justify-center font-light text-gray-text">
                      {sub?.examScore || 0}
                    </p>

                    <p className="border-r py-1 text-xs flex items-center justify-center font-light text-gray-text">
                      {sub?.totalScore || 0}
                    </p>

                    <p className="border-r py-1 text-xs flex items-center justify-center font-light text-gray-text">
                      {this.props?.getGrade(Number(sub?.totalScore))}
                    </p>

                    <p className="col-span-2 py-1 uppercase text-xxs flex items-center justify-center font-light text-gray-text border-r">
                      {this.props?.getRemark(Number(sub?.totalScore))}
                    </p>

                    <p className="py-2 text-xs flex items-center justify-center text-gray-text font-light">
                      {
                        this.props.getSubjectFromcards(
                          this.props?.firstTermScorecards,
                          sub
                        )?.totalScore
                      }
                    </p>
                  </div>
                );
              })}
            </div>

            {/**
            |--------------------------------------------------
            | grade scale
            |--------------------------------------------------
            */}
            <div className="border border-b-0 mb-4">
              <div className="px-1 bg-gray-light py-1 border-b">
                <h3 className="text-xs font-bold uppercase">Grade scale</h3>
              </div>

              <div className="grid grid-cols-3">
                {gradeScale?.map((item, i) => (
                  <div
                    key={i}
                    className={`grid grid-cols-2 border-b border-r flex-1 pl-1 ${
                      item.grade === "B3" ||
                      item.grade === "C6" ||
                      item.grade === ""
                        ? "border-r-0"
                        : ""
                    }`}
                  >
                    <p className="text-xs py-1 font-light text-gray-text">
                      {item?.range}
                    </p>
                    <p className="text-xs py-1 font-light text-gray-text">
                      {item.label} {item.grade ? `(${item.grade})` : ""}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            {/**
            |--------------------------------------------------
            | teacher's remark
            |--------------------------------------------------
            */}
            <div className="border p-1 mb-4">
              <div className="my-1 flex items-start gap-1 mb-2 border-b">
                <span className="text-xs text-gray-text font-bold">
                  Teacher's Remark:
                </span>
                <p className="text-xs italic pb-1 flex-1 px-1 text-gray-text font-light">
                  {this.props?.reportCard?.classTeacherRemark}
                </p>
              </div>

              <div className="flex items-start gap-3 flex-1">
                <span className="text-xs text-gray-text font-bold">
                  Teacher's Name:
                </span>
                <p className="text-xs capitalize pb-1 px-1 flex-1 text-gray-text font-light">
                  {this.props?.reportCard?.classTeacherTitle}{" "}
                  {this.props?.reportCard?.classTeacherLastName}{" "}
                  {this.props?.reportCard?.classTeacherFirstName}
                </p>
              </div>
            </div>

            {/**
            |--------------------------------------------------
            | Principal's remark
            |--------------------------------------------------
            */}
            <div className="border p-1 mb-4">
              <div className="flex items-start gap-3 flex-1 mb-5 pt-1 border-b">
                <span className="text-xs text-gray-text font-bold">
                  Principal's Remark:
                </span>
                <p className="text-xs italic pb-1 flex-1 px-1 text-gray-text font-light">
                  {this.props?.reportCard?.principalRemark}
                </p>
              </div>
            </div>

            {/**
            |--------------------------------------------------
            | school vacation and resumption
            |--------------------------------------------------
            */}
            <div className="flex items-center justify-between gap-5">
              <div className="flex-1 flex items-center gap-2">
                <span className="text-xs text-gray-text font-bold mb-2">
                  Vacation Date:
                </span>
                <p className="text-xs italic pb-[6px] font-light text-gray-text">
                  {this.props?.vacationDate}
                </p>
              </div>

              <div className="flex-1 flex items-center gap-2">
                <span className="text-xs text-gray-text font-bold mb-2">
                  Next term begins:
                </span>
                <p className="text-xs italic pb-[6px] font-light text-gray-text">
                  {this.props?.nextResumptionDate}
                </p>
              </div>
            </div>
          </div>

          {/**
          |--------------------------------------------------
          | column 2
          |--------------------------------------------------
          */}
          <div className="mb-2">
            {/**
            |--------------------------------------------------
            | Attendance
            |--------------------------------------------------
            */}
            <div className="border mb-4">
              <div className="px-1 bg-gray-light py-1 border-b">
                <h3 className="text-xs text-gray-text font-bold uppercase">
                  Attendance
                </h3>
              </div>

              <div className="grid grid-cols-3 border-b">
                <div className="py-1 px-1 border-r col-span-2">
                  <p className="text-xs text-gray-text font-bold">
                    Times school opened
                  </p>
                </div>
                <div className="py-1 px-1 border-r">
                  <p className="text-xs text-gray-text font-light">
                    {this.props?.attendanceOverview?.daysSchoolOpened}
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-3 border-b">
                <div className="py-1 px-1 border-r col-span-2">
                  <p className="text-xs text-gray-text font-bold">
                    No of times present
                  </p>
                </div>
                <div className="py-1 px-1 border-r">
                  <p className="text-xs text-gray-text font-light">
                    {this.props?.attendanceOverview?.daysPresent}
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-3">
                <div className="py-1 px-1 border-r col-span-2">
                  <p className="text-xs text-gray-text font-bold">
                    No of times absent
                  </p>
                </div>
                <div className="py-1 px-1 border-r">
                  <p className="text-xs text-gray-text font-light">
                    {this.props?.attendanceOverview?.daysAbsent}
                  </p>
                </div>
              </div>
            </div>

            {/**
            |--------------------------------------------------
            | Performance rating
            |--------------------------------------------------
            */}
            <div className="border mb-4">
              <div className="px-1 bg-gray-light py-1 border-b">
                <h3 className="text-xs text-gray-text font-bold uppercase">
                  Performance Summary
                </h3>
              </div>

              <div className="grid grid-cols-5 border-b">
                <div className="py-1 px-1 border-r col-span-2">
                  <p className="text-xs text-gray-text font-bold">
                    Mark obtained
                  </p>
                </div>
                <div className="py-1 px-1 border-r">
                  <p className="text-xs text-gray-text font-light">
                    {this.props?.reportCard?.obtainedScore}
                  </p>
                </div>

                <div className="py-1 px-1 border-r">
                  <p className="text-xs text-gray-text font-bold">%tage</p>
                </div>
                <div className="py-1 px-1">
                  <p className="text-xs text-gray-text font-light">
                    {`${this.props?.reportCard?.average}%`}
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-5 border-b">
                <div className="py-1 pl-1 border-r col-span-2">
                  <p className="text-xs text-gray-text font-bold">
                    Mark obtainable
                  </p>
                </div>
                <div className="py-1 px-1 border-r">
                  <p className="text-xs text-gray-text font-light">
                    {this.props?.reportCard?.obtainableScore}
                  </p>
                </div>

                <div className="py-1 px-1 border-r">
                  <p className="text-xs text-gray-text font-bold">Grade</p>
                </div>
                <div className="py-1 px-1">
                  <p className="text-xs text-gray-text font-light">
                    {this.props?.reportCard?.grade}
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-5 border-b">
                <div className="py-1 pl-1 border-r col-span-2">
                  <p className="text-xs text-gray-text font-bold">Class Size</p>
                </div>
                <div className="py-1 px-1 border-r">
                  <p className="text-xs text-gray-text font-light">
                    {this.props?.reportCard?.classSize}
                  </p>
                </div>

                <div className="py-1 px-1 border-r">
                  <p className="text-xs text-gray-text font-bold">Position</p>
                </div>
                <div className="py-1 px-1">
                  <p className="text-xs text-gray-text font-light">
                    {this.props?.reportCard?.position}
                  </p>
                </div>
              </div>

              {this.props?.endSessionRemark ? (
                <div className="flex items-center justify-center py-1">
                  <p className="text-xs text-gray-text font-bold">
                    {this.props?.endSessionRemark}
                  </p>
                </div>
              ) : null}
            </div>

            {/**
            |--------------------------------------------------
            | Behavior & skills header
            |--------------------------------------------------
            */}
            <div className="border border-b-0">
              <div className="px-1 bg-gray-light py-1 border-b">
                <h3 className="text-xs text-gray-text font-bold uppercase">
                  Behavior & Skills
                </h3>
                <div className="mt-1 grid grid-cols-3 gap-1">
                  <p className="text-xs text-gray-text">5 = Excellent</p>
                  <p className="text-xs text-gray-text">4 = Good</p>
                  <p className="text-xs text-gray-text">3 = Fair</p>
                  <p className="text-xs text-gray-text">2 = Fair</p>
                  <p className="text-xs text-gray-text">1 = Poor</p>
                </div>
              </div>

              <div className="flex items-center pl-1">
                <h4 className="text-xs flex-1 text-gray-text font-bold uppercase">
                  {" "}
                </h4>

                <div className="border-l w-4 text-xs text-gray-text text-center py-1">
                  5
                </div>
                <div className="border-l w-4 text-xs text-gray-text text-center py-1">
                  4
                </div>
                <div className="border-l w-4 text-xs text-gray-text text-center py-1">
                  3
                </div>
                <div className="border-l w-4 text-xs text-gray-text text-center py-1">
                  2
                </div>
                <div className="border-l w-4 text-xs text-gray-text text-center py-1">
                  1
                </div>
              </div>
            </div>

            {/**
            |--------------------------------------------------
            | Psychomotor skills
            |--------------------------------------------------
            */}
            <div className="border border-b-0">
              <div className="flex items-center pl-1 border-b py-1">
                <h4 className="text-xs flex-1 text-gray-text font-bold uppercase">
                  Psychomotor Skills
                </h4>
              </div>

              {this.props?.psychomotorsKils?.map((item, i) => (
                <div
                  className="flex items-center pl-1 border-b last:border-b-0"
                  key={i}
                >
                  <h4 className="text-xs text-gray-text flex-1">
                    {item?.label}
                  </h4>

                  <div className="border-l w-4 text-xs text-gray-text text-center h-5 flex items-center justify-center font-light">
                    {item?.value === "5" ? <AiOutlineCheck /> : null}
                  </div>
                  <div className="border-l w-4 text-xs text-gray-text text-center h-5 flex items-center justify-center font-light">
                    {item?.value === "4" ? <AiOutlineCheck /> : null}
                  </div>
                  <div className="border-l w-4 text-xs text-gray-text text-center h-5 flex items-center justify-center font-light">
                    {item?.value === "3" ? <AiOutlineCheck /> : null}
                  </div>
                  <div className="border-l w-4 text-xs text-gray-text text-center h-5 flex items-center justify-center font-light">
                    {item?.value === "2" ? <AiOutlineCheck /> : null}
                  </div>
                  <div className="border-l w-4 text-xs text-gray-text text-center h-5 flex items-center justify-center font-light">
                    {item?.value === "1" ? <AiOutlineCheck /> : null}
                  </div>
                </div>
              ))}
            </div>

            {/**
            |--------------------------------------------------
            | Affective Areas
            |--------------------------------------------------
            */}
            <div className="border mb-4">
              <div className="flex items-center pl-1 border-b py-1">
                <h4 className="text-xs text-gray-text flex-1 font-bold uppercase">
                  Affective Areas
                </h4>
              </div>

              {this.props?.affectiveAreasSkill?.map((item, i) => (
                <div
                  className="flex items-center pl-1 border-b last:border-b-0"
                  key={i}
                >
                  <h4 className="text-xs text-gray-text flex-1">
                    {item?.label}
                  </h4>

                  <div className="border-l w-4 text-xs text-gray-text text-center h-5 flex items-center justify-center font-light">
                    {item?.value === "5" ? <AiOutlineCheck /> : null}
                  </div>
                  <div className="border-l w-4 text-xs text-gray-text text-center h-5 flex items-center justify-center font-light">
                    {item?.value === "4" ? <AiOutlineCheck /> : null}
                  </div>
                  <div className="border-l w-4 text-xs text-gray-text text-center h-5 flex items-center justify-center font-light">
                    {item?.value === "3" ? <AiOutlineCheck /> : null}
                  </div>
                  <div className="border-l w-4 text-xs text-gray-text text-center h-5 flex items-center justify-center font-light">
                    {item?.value === "2" ? <AiOutlineCheck /> : null}
                  </div>
                  <div className="border-l w-4 text-xs text-gray-text text-center h-5 flex items-center justify-center font-light">
                    {item?.value === "1" ? <AiOutlineCheck /> : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
