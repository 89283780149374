import React from "react";
import { IoClose } from "react-icons/io5";

export default function TableInput({
  dataKey,
  index,
  data,
  hover,
  setHover,
  handleReset,
  handleUpdatePayload,
}) {
  const isActive = hover.key === dataKey && hover?.index === index;

  return (
    <div
      onMouseOver={() => {
        setHover({ key: dataKey, index });
      }}
      onMouseLeave={() => {
        setHover({ key: null, index: -1 });
      }}
      className={`flex items-center overflow-hidden w-full border border-white ${
        isActive ? "border !border-primary" : ""
      }`}
    >
      <input
        onChange={(e) => {
          handleUpdatePayload(e.target.value, dataKey, index);
        }}
        value={data?.[dataKey] || ""}
        className="text-sm py-1 px-1 border-0 outline-0 bg-transparent flex-1 h-full text-light_slate_grey w-full"
      />

      {isActive ? (
        <button
          className="flex items-center justify-center px-1"
          onClick={() => handleReset(dataKey, index)}
        >
          <IoClose className="text-coral_red text-base" />
        </button>
      ) : null}
    </div>
  );
}
