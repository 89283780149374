import React from "react";
import FormButton from "../../form/FormButton";
import { formatCurrency, handleToast } from "../../../utils/functions";
import useIncreaseStudentThreshold from "../../../api/payment/useIncreaseStudentThreshold";

export default function IncreaseStudentThreshold({
  threshold,
  closeSideModal,
  billingDetails,
}) {
  console.log(billingDetails);
  const { request, loading } = useIncreaseStudentThreshold(
    billingDetails?.schoolId
  );

  async function updateSettings() {
    if (!threshold) return handleToast("Enter a valid threshold");

    const isSuccessful = await request({
      studentThreshold: Number(threshold),
    });

    if (isSuccessful) {
      closeSideModal({ refresh: true });
    }
  }
  return (
    <div>
      <div className="mb-6">
        <h3 className="side_page_title mb-1">Increase Student Threshold</h3>

        <p className="text-sm text-independence mb-2">
          Are you sure you want to increase student threshold?
        </p>
        <p className="text-sm text-independence mb-1">
          - Your current threshold is {billingDetails?.studentThreshold}
        </p>
        <p className="text-sm text-independence mb-1">
          - You are asking to increase the threshold by {threshold}
        </p>
        <p className="text-sm text-independence mb-1">
          - You will be charged ₦
          {formatCurrency(Number(threshold) * billingDetails?.plan?.amount)} +
          7.5% VAT for this action
        </p>
        <p className="text-sm text-independence mb-1">
          - Your total student threshold will be{" "}
          {Number(billingDetails?.studentThreshold) + Number(threshold)}
        </p>
      </div>

      <div>
        <FormButton
          loading={loading}
          disabled={loading}
          onClick={updateSettings}
          title={"Increase Threshold"}
        />
      </div>

      <p
        className="text-center text-coral_red mt-1 py-3 cursor-pointer"
        onClick={closeSideModal}
      >
        Cancel
      </p>
    </div>
  );
}
