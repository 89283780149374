import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { handleToast } from "../../../shared/utils/functions";
import SideModal from "../../../shared/components/general/SideModal";
import BillingAddress from "../../../shared/components/settings/billing/BillingAddress";
import BillingOverview from "../../../shared/components/settings/billing/BillingOverview";
import BillingNavigationBar from "../../../shared/components/settings/billing/BillingNavigationBar";
import useFetchSchoolBillingDetails from "../../../shared/api/payment/useFetchSchoolBillingDetails";
import BillingEmailSettings from "../../../shared/components/settings/billing/BillingEmailSettings";
import BillingThresholdSettings from "../../../shared/components/settings/billing/BillingThresholdSettings";
import IncreaseStudentThreshold from "../../../shared/components/settings/billing/IncreaseStudentThreshold";

export default function Billing() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const action = query.get("action");

  const { schoolId } = useParams();
  const navigate = useNavigate();

  const [threshold, setThreshold] = useState("");

  const { data: billingDetails, request: getSchoolBillingDetails } =
    useFetchSchoolBillingDetails(schoolId);

  function closeSideModal(params) {
    navigate(`/${schoolId}/settings/billing`);

    setThreshold("");
    if (params?.refresh) {
      getSchoolBillingDetails(schoolId);
    }
  }

  function handleNavigateToThreshold() {
    if (!threshold) return handleToast("Enter a minimum amount of 1", "error");

    navigate(`/${schoolId}/settings/billing?action=increase-threshold`);
  }

  /**
  |--------------------------------------------------
  | 
  |--------------------------------------------------
  */
  return (
    <div>
      <div className="page">
        {/**
        |--------------------------------------------------
        | Page title
        |--------------------------------------------------
        */}
        <div className="mb-10 flex items-center justify-between">
          <h1 className="page_title">Billing</h1>
        </div>

        {/**
        |--------------------------------------------------
        | Navigation bar
        |--------------------------------------------------
        */}
        <BillingNavigationBar activeIndex={0} />

        {/**
        |--------------------------------------------------
        | Main content
        |--------------------------------------------------
        */}
        <div className="main_content !pb-0 mb-10">
          {/**
          |--------------------------------------------------
          | Billing Overview
          |--------------------------------------------------
          */}
          <BillingOverview billingDetails={billingDetails} />
        </div>

        <div className="main_content mb-10">
          {/**
          |--------------------------------------------------
          | Billing Threshold Settings
          |--------------------------------------------------
          */}
          <BillingThresholdSettings
            threshold={threshold}
            setThreshold={setThreshold}
            billingDetails={billingDetails}
            handleNavigateToThreshold={handleNavigateToThreshold}
          />
        </div>

        <div className="main_content mb-10">
          {/**
          |--------------------------------------------------
          | Billing Invoice settings
          |--------------------------------------------------
          */}
          <BillingEmailSettings billingDetails={billingDetails} />
        </div>

        <div className="main_content">
          {/**
          |--------------------------------------------------
          | Billing address
          |--------------------------------------------------
          */}
          <BillingAddress billingDetails={billingDetails} />
        </div>
      </div>

      <SideModal isVisible={action} closeSideModal={closeSideModal}>
        {action === "increase-threshold" ? (
          <IncreaseStudentThreshold
            threshold={threshold}
            closeSideModal={closeSideModal}
            billingDetails={billingDetails}
          />
        ) : null}
      </SideModal>
    </div>
  );
}
