import React, { useEffect, useState } from "react";

import FormInput from "../../form/FormInput";
import FormButton from "../../form/FormButton";
import { handleToast } from "../../../utils/functions";
import useUpdateBillingSettings from "../../../api/payment/useUpdateBillingSettings";

export default function BillingEmailSettings({ billingDetails }) {
  const { request, loading } = useUpdateBillingSettings();
  const [text, setText] = useState("");

  useEffect(() => {
    setText(billingDetails?.email || "");
  }, [billingDetails?.email]);

  async function updateSettings() {
    if (!text) return handleToast("Email is invalid", "error");

    await request(billingDetails?.billingDetailsId, { email: text });
  }

  return (
    <div className="table_wrapper !max-w-xl">
      {/**
        |--------------------------------------------------
        | Section header
        |--------------------------------------------------
        */}
      <div className="px-3">
        <h4 className="text-yankee_blue font-bold text-lg capitalize">
          Invoice Email Recipient
        </h4>

        <p className="text-sm text-greyX11 mb-5">
          Invoice emails is sent to the school owner's email by default. If you
          want to use another email address to receive invoice emails, please
          update it here.
        </p>

        {/**
        |--------------------------------------------------
        | Section form
        |--------------------------------------------------
        */}
        <div className="flex gap-5 items-end">
          {/**
          |--------------------------------------------------
          | Email input
          |--------------------------------------------------
          */}
          <FormInput
            value={text}
            className="mb-0 flex-1"
            placeholder={"Enter email address"}
            onChange={(e) => setText(e.currentTarget.value)}
          />

          {/**
          |--------------------------------------------------
          | update button
          |--------------------------------------------------
          */}
          <FormButton
            title={"Update"}
            loading={loading}
            onClick={updateSettings}
            className={"max-w-[100px]"}
          />
        </div>
      </div>
    </div>
  );
}
