import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { FaFileCsv } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  formatPhoneNumber,
  handleToast,
} from "../../../shared/utils/functions";
import PageLoader from "../../../shared/components/PageLoader";
import { selectCalendar } from "../../../redux/slices/school/selectors";
import useFetchClassDetails from "../../../shared/api/classes/useFetchClassDetails";
// import ClassNavigationBar from "../../../shared/components/classes/ClassNavigationBar";
import NewStudentForm from "../../../shared/components/classes/newStudent/NewStudentForm";
import useAddStudentToClass from "../../../shared/api/classes/students/useAddStudentToClass";
import UploadFileInstruction from "../../../shared/components/classes/newStudent/UploadFileInstruction";
import PreviewStudentsTable from "../../../shared/components/classes/newStudent/PreviewStudentsTable";
import useUploadStudentToClass from "../../../shared/api/classes/students/useUploadStudentToClass";
import { ClipLoader } from "react-spinners";

const NewStudent = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const action = query.get("action");

  const navigate = useNavigate();
  const filePickerRef = useRef(null);

  const { classId, schoolId } = useParams();
  const currentCalendar = useSelector(selectCalendar);
  const { loading: classLoading, data: classDetails } =
    useFetchClassDetails(classId);

  const [file, setFile] = useState(null);
  const [uploads, setUploads] = useState(null);
  const [uploadPayload, setUploadPayload] = useState(null);

  const [student, setStudent] = useState(null);
  const [guardian, setGuardian] = useState(null);
  const { request: registerStudentRequest, loading } = useAddStudentToClass();
  const { request: submitUpload, loading: submittingUploads } =
    useUploadStudentToClass();

  async function registerStudent(e) {
    e.preventDefault();
    student.schoolId = schoolId;
    student.address = guardian?.address;
    student.suffix = classDetails?.suffix;
    student.parentContact = guardian.phone;
    student.classId = classDetails?.classId;
    student.category = classDetails?.category;

    await registerStudentRequest({
      student,
      guardian,
      calendarId: currentCalendar?.calendarId,
    });
  }

  function selectImage(e) {
    setFile(e.target.files[0]);
  }

  const readCsvFile = useCallback(() => {
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const csvData = e.target.result;
        const rows = csvData.split("\n");

        if (!validateCsvHeader(rows[0])) {
          setFile(null);
          setUploads(null);
          setUploadPayload(null);
        }

        const jsonData = [];
        rows?.splice(1)?.forEach((row) => {
          const item = row.split(",");

          if (item?.length !== 9) {
            throw new Error(
              "Invalid CSV format, please make sure you are following the instruction"
            );
          }

          if (!validateCsvDataRow(row)) {
            setFile(null);
            setUploads(null);
            setUploadPayload(null);
          }

          if (item[0]) {
            const student = {
              firstName: item[0],
              lastName: item[1],
              middleName: item[2],
              dob: item[3],
              gender: item[4],
              name: item[5],
              phone: formatPhoneNumber(item[6]),
              occupation: item[7],
              address: item[8],
            };

            jsonData.push(student);
          }
        });
        setUploads(jsonData);
      } catch (error) {
        setFile(null);
        setUploads(null);
        setUploadPayload(null);
        handleToast(error.message, "error");
      }
    };

    reader.readAsText(file);
  }, [file]);

  useEffect(() => {
    if (file) {
      readCsvFile(file);
    }
  }, [file, readCsvFile]);

  // function resetFilePickerRef() {
  //   filePickerRef.current.value = "";
  //   setUploads(null);
  //   setUploadPayload(null);
  //   setUploadPayload(null);
  // }

  async function handleSubmitUpload() {
    if (submittingUploads) return;

    if (!uploadPayload?.length) {
      setUploads(null);
      setUploadPayload(null);
      setFile(null);
      return handleToast("Upload a valid CSV file");
    }

    const students = uploadPayload?.map((el) => ({
      student: {
        firstName: el?.firstName,
        lastName: el?.lastName,
        dob: el?.dob,
        gender: el?.gender,
      },
      guardian: {
        name: el?.name,
        phone: el?.phone,
        occupation: el?.occupation,
        address: el?.address,
      },
    }));

    const _payload = {
      calendarId: currentCalendar?.calendarId,
      students,
    };

    await submitUpload(_payload);
  }

  if (classLoading) {
    return <PageLoader loading={classLoading} />;
  }

  return (
    <div>
      <div className={`${action ? "full_page" : "page"}`}>
        <div className="mb-5 flex items-center justify-between">
          <div className="page_title_container">
            <h1 className="page_title">
              Add Student{" "}
              {classDetails
                ? `(${classDetails?.category} ${classDetails?.suffix})`
                : null}
            </h1>
          </div>

          {uploads?.length ? (
            <button
              className="flex items-center justify-center gap-1 bg-primary h-8 xxs:h-8 ssm:h-9 rounded-sm text-white px-3 min-w-[80px]"
              onClick={handleSubmitUpload}
            >
              {submittingUploads ? (
                <ClipLoader loading={submittingUploads} />
              ) : (
                <>
                  <FaFileCsv className="text-sm" />
                  <small>Submit</small>
                </>
              )}
            </button>
          ) : (
            <button
              className="flex items-center justify-center gap-1 bg-primary h-8 xxs:h-8 ssm:h-9 rounded-sm text-white px-3 min-w-[80px]"
              // onClick={() => filePickerRef.current.click()}
              onClick={() =>
                navigate(
                  `/${schoolId}/classes/${classId}/add-student?action=upload-file`
                )
              }
            >
              <FaFileCsv className="text-sm" />
              <small>Upload File</small>
            </button>
          )}
        </div>

        <input
          hidden
          name="file"
          type={"file"}
          ref={filePickerRef}
          accept=".csv, .xlsx"
          onChange={selectImage}
        />

        <div className="main_content">
          {!action ? (
            <NewStudentForm
              category={classDetails?.category}
              guardian={guardian}
              setGuardian={setGuardian}
              student={student}
              setStudent={setStudent}
              loading={loading}
              onSubmit={registerStudent}
            />
          ) : null}

          {action === "upload-file" ? (
            <UploadFileInstruction
              file={file}
              showInstruction={!uploads?.length}
              handleSelectFile={() => filePickerRef.current.click()}
            />
          ) : null}

          {action === "upload-file" && uploads?.length ? (
            <>
              <h3 className="text-black-text font-semibold mb-3 px-3">
                Preview Students
              </h3>

              <PreviewStudentsTable
                data={uploads}
                payload={uploadPayload}
                setPayload={setUploadPayload}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

function validateCsvHeader(row) {
  const items = row.split(",");

  const firstName = items[0]?.trim()?.toLowerCase();
  const lastName = items[1]?.trim()?.toLowerCase();
  const middleName = items[2]?.trim()?.toLowerCase();
  const dob = items[3]?.trim()?.toLowerCase();
  const gender = items[4]?.trim()?.toLowerCase();
  const name = items[5]?.trim()?.toLowerCase();
  const phone = items[6]?.trim()?.toLowerCase();
  const occupation = items[7]?.trim()?.toLowerCase();
  const address = items[8]?.trim()?.toLowerCase()?.replace("\\r", "");

  if (firstName !== "first name")
    throw new Error("Invalid header format for first name");

  if (lastName !== "last name")
    throw new Error("Invalid header format for last name");

  if (middleName !== "middle name")
    throw new Error("Invalid header format for middle name");

  if (dob !== "date of birth")
    throw new Error("Invalid header format for date of birth");

  if (gender !== "gender") throw new Error("Invalid header format for gender");

  if (name !== "guardian last name")
    throw new Error("Invalid header format for guardian last name");

  if (address !== "address")
    throw new Error("Invalid header format for address");

  if (phone !== "guardian phone number")
    throw new Error("Invalid header format for phone");

  if (occupation !== "guardian occupation")
    throw new Error("Invalid header format for occupation");

  return true;
}

function validateCsvDataRow(row) {
  const items = row.split(",");

  const firstName = items[0]?.trim()?.toLowerCase();
  const lastName = items[1]?.trim()?.toLowerCase();
  const middleName = items[2]?.trim()?.toLowerCase();
  const dob = items[3]?.trim()?.toLowerCase();
  const gender = items[4]?.trim()?.toLowerCase();
  const name = items[5]?.trim()?.toLowerCase();
  const phone = items[6]?.trim()?.toLowerCase();
  const occupation = items[7]?.trim()?.toLowerCase();
  const address = items[8]?.trim()?.toLowerCase()?.replace("\\r", "");

  if (firstName?.length < 3)
    throw new Error("Invalid data format for first name");

  if (lastName?.length < 3)
    throw new Error("Invalid data format for last name");

  if (middleName?.length < 3)
    throw new Error("Invalid data format for middle name");

  if (dob?.length < 10)
    throw new Error("Invalid data format for date of birth");

  if (gender?.length < 4 || gender?.length > 6)
    throw new Error("Invalid data format for gender");

  if (name?.length < 3)
    throw new Error("Invalid data format for guardian last name");

  if (address?.length < 3) throw new Error("Invalid data format for address");

  if (phone?.length < 10) throw new Error("Invalid data format for phone");

  if (occupation?.length < 3)
    throw new Error("Invalid data format for occupation");

  return true;
}

export default NewStudent;
