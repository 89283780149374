import React from "react";

import FormInput from "../../form/FormInput";
import FormButton from "../../form/FormButton";

export default function BillingThresholdSettings({
  billingDetails,
  handleNavigateToThreshold,
  setThreshold,
  threshold,
}) {
  return (
    <div className="table_wrapper !max-w-xl">
      {/**
        |--------------------------------------------------
        | Section header
        |--------------------------------------------------
        */}
      <div className="px-3">
        <h4 className="text-yankee_blue font-bold text-lg capitalize">
          Student Threshold
        </h4>

        <p className="text-sm text-greyX11 mb-5">
          Your current maximum amount of students that can be added to your
          classes is{" "}
          <span className="text-independence font-bold">
            {billingDetails?.studentThreshold}
          </span>
          . Increase student threshold to add more students to your classes. You
          will be charged immediately for increasing the threshold.
        </p>

        {/**
        |--------------------------------------------------
        | Section form
        |--------------------------------------------------
        */}
        <div className="flex gap-5 items-end">
          {/**
          |--------------------------------------------------
          | Email input
          |--------------------------------------------------
          */}
          <FormInput
            type="number"
            value={threshold}
            className="mb-0 flex-1"
            placeholder={"Enter amount"}
            label={"Increase Student Threshold"}
            onChange={(e) => setThreshold(e.currentTarget.value)}
          />

          {/**
          |--------------------------------------------------
          | update button
          |--------------------------------------------------
          */}
          <FormButton
            title={"Continue"}
            onClick={handleNavigateToThreshold}
            className={"max-w-[100px]"}
          />
        </div>
      </div>
    </div>
  );
}
