import React, { useEffect, useState } from "react";

import FormButton from "../../form/FormButton";
import { AiOutlineCheck } from "react-icons/ai";
import useAddBehavourialSkillRemark from "../../../api/cards/useAddBehavourialSkillRemark";

export const psychomotorSkills = [
  { label: "Tools Handling", value: "", key: "toolsHandling" },
  { label: "Drawing / Painting", value: "", key: "drawingAndPainting" },
  { label: "Handwriting", value: "", key: "handwriting" },
  { label: "Verbal Fluency", value: "", key: "verbalFluency" },
  { label: "Sports & Games", value: "", key: "gamesAndSport" },
  { label: "Music Skills", value: "", key: "musicalSkills" },
  { label: "Memorization", value: "", key: "memorization" },
];

export const affectiveAreasSkills = [
  { label: "Punctuality", value: "", key: "punctuality" },
  { label: "Neatness", value: "", key: "neatness" },
  { label: "Politeness", value: "", key: "politeness" },
  { label: "Honesty", value: "", key: "honesty" },
  {
    label: "Relationship with others",
    value: "",
    key: "relationshipWithOthers",
  },
  { label: "Leadership", value: "", key: "leadership" },
  { label: "Emotional Stability", value: "", key: "emotionalStability" },
  { label: "Attitude to school work", value: "", key: "attitudeToSchoolWork" },
  { label: "Attentiveness", value: "", key: "attentiveness" },
  { label: "Perseverance", value: "", key: "perseverance" },
  { label: "Helping Others", value: "", key: "helpingOthers" },
  { label: "Imitativeness", value: "", key: "imitativeness" },
  { label: "Organisational Ability", value: "", key: "organisationalAbility" },
];

export default function BehavourialSkill({
  reportCard,
  fetchReportCard,
  closeSideModal,
  action,
}) {
  const [psychomotorPayload, setPsychomotorPayload] = useState([]);
  const [affectiveAreasPayload, setAffectiveAreasPayload] = useState([]);
  const { request: submitRequest, loading: submitting } =
    useAddBehavourialSkillRemark();

  async function submitRemark() {
    const payload = {
      skills: [...psychomotorPayload, ...affectiveAreasPayload],
    };

    const isSuccess = await submitRequest(reportCard?.reportCardId, payload);

    if (isSuccess) {
      await fetchReportCard();
      closeSideModal();
    }
  }

  useEffect(() => {
    const _psychomotorPayload = psychomotorSkills?.map((item) => ({
      ...item,
      value: reportCard?.[item.key] || "",
    }));
    setPsychomotorPayload(_psychomotorPayload);

    const _affectiveAreasPayload = affectiveAreasSkills?.map((item) => ({
      ...item,
      value: reportCard?.[item.key] || "",
    }));
    setAffectiveAreasPayload(_affectiveAreasPayload);
  }, [reportCard]);

  function handleSelect(params, value, arr, setter) {
    const newArray = [...arr];
    const foundIndex = newArray?.findIndex((item) => item?.key === params?.key);
    let foundItem = newArray?.[foundIndex];

    if (foundItem?.value === value) {
      foundItem.value = "";
    } else {
      foundItem.value = value;
    }

    newArray[foundIndex] = foundItem;
    setter(newArray);
  }

  if (action !== "behavourial-skill") return null;
  return (
    <div>
      <div className="mb-3">
        <h2 className="side_page_title !mb-2 capitalize">Behavourial Skill</h2>
        <p className="text-sm text-independence">
          Add behavourial skill to report card
        </p>
      </div>

      <div className="mt-5">
        <div className="border mb-5">
          <div className="flex items-center pl-1 border-b">
            <h4 className="text-sm flex-1 font-bold uppercase">
              Psychomotor Skills
            </h4>

            <div className="border-l w-5 text-sm text-center py-2">5</div>
            <div className="border-l w-5 text-sm text-center py-2">4</div>
            <div className="border-l w-5 text-sm text-center py-2">3</div>
            <div className="border-l w-5 text-sm text-center py-2">2</div>
            <div className="border-l w-5 text-sm text-center py-2">1</div>
          </div>

          {psychomotorPayload?.map((item, i) => (
            <div
              className="flex items-center pl-1 border-b last:border-b-0"
              key={i}
            >
              <h4 className="text-sm flex-1">{item?.label}</h4>

              <div
                onClick={() =>
                  handleSelect(
                    item,
                    "5",
                    psychomotorPayload,
                    setPsychomotorPayload
                  )
                }
                className="border-l w-5 text-sm text-center h-7 flex items-center justify-center cursor-pointer"
              >
                {item?.value === "5" ? <AiOutlineCheck /> : null}
              </div>
              <div
                onClick={() =>
                  handleSelect(
                    item,
                    "4",
                    psychomotorPayload,
                    setPsychomotorPayload
                  )
                }
                className="border-l w-5 text-sm text-center h-7 flex items-center justify-center cursor-pointer"
              >
                {item?.value === "4" ? <AiOutlineCheck /> : null}
              </div>
              <div
                onClick={() =>
                  handleSelect(
                    item,
                    "3",
                    psychomotorPayload,
                    setPsychomotorPayload
                  )
                }
                className="border-l w-5 text-sm text-center h-7 flex items-center justify-center cursor-pointer"
              >
                {item?.value === "3" ? <AiOutlineCheck /> : null}
              </div>
              <div
                onClick={() =>
                  handleSelect(
                    item,
                    "2",
                    psychomotorPayload,
                    setPsychomotorPayload
                  )
                }
                className="border-l w-5 text-sm text-center h-7 flex items-center justify-center cursor-pointer"
              >
                {item?.value === "2" ? <AiOutlineCheck /> : null}
              </div>
              <div
                onClick={() =>
                  handleSelect(
                    item,
                    "1",
                    psychomotorPayload,
                    setPsychomotorPayload
                  )
                }
                className="border-l w-5 text-sm text-center h-7 flex items-center justify-center cursor-pointer"
              >
                {item?.value === "1" ? <AiOutlineCheck /> : null}
              </div>
            </div>
          ))}
        </div>

        <div className="border">
          <div className="flex items-center pl-1 border-b">
            <h4 className="text-sm flex-1 font-bold uppercase">
              Affective Areas
            </h4>

            <div className="border-l w-5 text-sm text-center py-2">5</div>
            <div className="border-l w-5 text-sm text-center py-2">4</div>
            <div className="border-l w-5 text-sm text-center py-2">3</div>
            <div className="border-l w-5 text-sm text-center py-2">2</div>
            <div className="border-l w-5 text-sm text-center py-2">1</div>
          </div>

          {affectiveAreasPayload?.map((item, i) => (
            <div
              className="flex items-center pl-1 border-b last:border-b-0"
              key={i}
            >
              <h4 className="text-sm flex-1">{item?.label}</h4>

              <div
                onClick={() =>
                  handleSelect(
                    item,
                    "5",
                    affectiveAreasPayload,
                    setAffectiveAreasPayload
                  )
                }
                className="border-l w-5 text-sm text-center h-7 flex items-center justify-center cursor-pointer"
              >
                {item?.value === "5" ? <AiOutlineCheck /> : null}
              </div>
              <div
                onClick={() =>
                  handleSelect(
                    item,
                    "4",
                    affectiveAreasPayload,
                    setAffectiveAreasPayload
                  )
                }
                className="border-l w-5 text-sm text-center h-7 flex items-center justify-center cursor-pointer"
              >
                {item?.value === "4" ? <AiOutlineCheck /> : null}
              </div>
              <div
                onClick={() =>
                  handleSelect(
                    item,
                    "3",
                    affectiveAreasPayload,
                    setAffectiveAreasPayload
                  )
                }
                className="border-l w-5 text-sm text-center h-7 flex items-center justify-center cursor-pointer"
              >
                {item?.value === "3" ? <AiOutlineCheck /> : null}
              </div>
              <div
                onClick={() =>
                  handleSelect(
                    item,
                    "2",
                    affectiveAreasPayload,
                    setAffectiveAreasPayload
                  )
                }
                className="border-l w-5 text-sm text-center h-7 flex items-center justify-center cursor-pointer"
              >
                {item?.value === "2" ? <AiOutlineCheck /> : null}
              </div>
              <div
                onClick={() =>
                  handleSelect(
                    item,
                    "1",
                    affectiveAreasPayload,
                    setAffectiveAreasPayload
                  )
                }
                className="border-l w-5 text-sm text-center h-7 flex items-center justify-center cursor-pointer"
              >
                {item?.value === "1" ? <AiOutlineCheck /> : null}
              </div>
            </div>
          ))}
        </div>

        <FormButton
          onClick={submitRemark}
          loading={submitting}
          className={"mt-10"}
          title="Update Skills"
        />

        <FormButton
          title={"Cancel"}
          className={"!bg-transparent !text-coral_red mt-3 !border-coral_red"}
          onClick={closeSideModal}
        />
      </div>
    </div>
  );
}
