import React from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";

import IMAGES from "../../../shared/constant/images";
import Loader from "../../../shared/components/Loader";
import EmptyTable from "../../../shared/components/EmptyTable";
import useFetchReportCardById from "../../../shared/api/cards/useFetchReportCardById";
import ArchivedScorecardsTableBody from "../../../shared/components/archives/reportCards/ArchivedScorecardsTableBody";
import ArchivedScorecardsTableHeader from "../../../shared/components/archives/reportCards/ArchivedScorecardsTableHeader";
import ArchivedScorecardsTableOverview from "../../../shared/components/archives/reportCards/ArchivedScorecardsTableOverview";

export default function ArchivedReportcardDetails() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const session = query.get("session");

  const navigate = useNavigate();

  const { classId, schoolId, reportCardId } = useParams();

  const { data: reportcard, loading: loadingReportCard } =
    useFetchReportCardById(reportCardId);

  if (loadingReportCard) {
    return <Loader loading={loadingReportCard} />;
  }

  return (
    <div>
      <div className="full_page">
        <div className="mb-5 flex items-center justify-between">
          <div className="page_title_container capitalize">
            {`${reportcard?.student?.lastName || ""} ${
              reportcard?.student?.firstName || ""
            }'s`}{" "}
            Report Card
          </div>

          {reportcard ? (
            <button
              className="flex items-center justify-center gap-1 bg-primary h-8 xxs:h-8 ssm:h-9 rounded-sm text-white px-3 min-w-[80px]"
              // onClick={() => navigate(-1)}
              onClick={() =>
                navigate(
                  `/${reportcard?.schoolId}/classes/${reportcard?.classId}/students/${reportcard?.studentId}/reportcard/${reportcard?.reportCardId}?session=${reportcard?.session}&term=${reportcard?.term}`
                )
              }
            >
              {/* <FaPlus className="text-sm" /> */}
              <small>Preview Report Card</small>
            </button>
          ) : null}
        </div>

        {/* <h1 className="page_title mb-5 capitalize">
          {`${reportcard?.student?.lastName || ""} ${
            reportcard?.student?.firstName || ""
          }'s`}{" "}
          Report Card
        </h1> */}

        <div className="table_wrapper">
          <div className="main_content_header">
            <NavLink
              to={`/${schoolId}/classes/${classId}/archives/reportcard?session=${session}`}
            >
              <button className="main_content_header_btn">Back to cards</button>
            </NavLink>
          </div>
        </div>

        {!loadingReportCard && reportcard ? (
          <div className="main_content mb-10">
            <ArchivedScorecardsTableOverview
              obtainableScore={reportcard?.obtainableScore}
              obtainedScore={reportcard?.obtainedScore}
              average={`${reportcard?.average}%`}
              reportCard={reportcard}
            />

            <div className="table_wrapper">
              <div className="min-w-[800px]">
                <ArchivedScorecardsTableHeader />

                {loadingReportCard ? (
                  <Loader loading={loadingReportCard} />
                ) : null}

                {!loadingReportCard && !reportcard?.scorecards?.length ? (
                  <EmptyTable
                    image={IMAGES.bookImage}
                    message={"No scorecard records found"}
                  />
                ) : null}

                <ArchivedScorecardsTableBody data={reportcard?.scorecards} />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
