import { useCallback, useEffect, useState } from "react";

import client from "../../client";
import handleApiError from "../../../hooks/handleApiError";

const useFetchClassStudents = (classId, page, setPagination) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const request = useCallback(async () => {
    setLoading(true);
    setData(null);
    setPagination?.(null);

    try {
      const res = await client.get(`/classes/${classId}/students?page=${page}`);

      setData(res.data?.data);
      if (res.data?.pagination) setPagination?.(res.data?.pagination);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, [classId, page, setPagination]);

  useEffect(() => {
    if (classId) request();
  }, [request, classId]);

  return { loading, data };
};

export default useFetchClassStudents;
