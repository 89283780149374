import moment from "moment";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  capitalizeString,
  formatDate,
  formatDateTwo,
  formatToStringDate,
  getDayStringFromDate,
  handleToast,
} from "../../../shared/utils/functions";
import {
  selectCalendar,
  selectSchoolDetails,
} from "../../../redux/slices/school/selectors";
import Loader from "../../../shared/components/Loader";
import PageLoader from "../../../shared/components/PageLoader";
import SideModal from "../../../shared/components/general/SideModal";
import SelectInput from "../../../shared/components/form/SelectInput";
import AttendanceTable from "../../../shared/components/classes/attendance/AttendanceTable";
import useFetchClassStudents from "../../../shared/api/classes/students/useFetchClassStudents";
import StudentAttendance from "../../../shared/components/classes/attendance/StudentAttendance";
import AttendanceOverview from "../../../shared/components/classes/attendance/AttendanceOverview";
import useMarkClassAttendance from "../../../shared/api/classes/attendance/useMarkClassAttendance";
import NewAttendanceSheets from "../../../shared/components/classes/attendance/NewAttendanceSheets";
import useFetchClassAttendance from "../../../shared/api/classes/attendance/useFetchClassAttendance";

const Attendance = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const week = query.get("week");
  const period = query.get("period");
  const action = query.get("action");
  const studentId = query.get("selected");

  const navigate = useNavigate();
  const { classId, schoolId } = useParams();
  const calendar = useSelector(selectCalendar);
  const school = useSelector(selectSchoolDetails);

  /**
  |--------------------------------------------------
  | current date state
  |--------------------------------------------------
  */
  const [currentDate, setCurrentDate] = useState(null);

  /**
  |--------------------------------------------------
  | Api hook to get class attendance
  |--------------------------------------------------
  */
  const {
    data: attendanceSheet,
    loading: attendanceLoading,
    request: fetchClassAttendance,
  } = useFetchClassAttendance();

  const { loading: loadingStudents, data: students } =
    useFetchClassStudents(classId);

  /**
  |--------------------------------------------------
  | State to track marked students attendance
  |--------------------------------------------------
  */
  const [markedStudentList, setMarkedStudentList] = useState([]);

  /**
  |--------------------------------------------------
  | Api hook to submit attendance
  |--------------------------------------------------
  */
  const { request: submitAttendanceRequest, loading: submitting } =
    useMarkClassAttendance();

  /**
  |--------------------------------------------------
  | Fetch class attendance when week or calendar changes
  |--------------------------------------------------
  */
  useEffect(() => {
    if (calendar && week !== "undefined") {
      fetchClassAttendance(Number(week), calendar?.calendarId);
    }
  }, [calendar, fetchClassAttendance, week]);

  /**
  |--------------------------------------------------
  | setActiveWeek when calendar changes
  |--------------------------------------------------
  */
  useEffect(() => {
    if (week === "undefined" || week === "null" || !period)
      navigate(
        `/${schoolId}/classes/${classId}/attendance?week=${calendar.week}&period=morning`
      );
  }, [calendar, navigate, schoolId, classId, week, period]);

  /**
  |--------------------------------------------------
  | Check if date is in the future
  |--------------------------------------------------
  */
  function checkFutureDate() {
    if (moment().diff(new Date(currentDate)) >= 0) return false;
    else return true;
  }

  /**
  |--------------------------------------------------
  | function to submit marked attendance
  |--------------------------------------------------
  */
  async function handleSubmitAttendance() {
    if (submitting) return;

    if (!markedStudentList?.length)
      return handleToast("Attendance sheet is empty", "warning");

    if (checkFutureDate()) {
      return handleToast("Marking future attendance is restricted", "warning");
    }

    if (week <= 0) {
      return handleToast("Week cannot be less than 1", "warning");
    }

    const isSuccess = await submitAttendanceRequest(classId, {
      week: week,
      schoolId: school?.schoolId,
      date: formatDate(currentDate),
      calendarId: calendar?.calendarId,
      attendances: [...markedStudentList],
      day: getDayStringFromDate(currentDate),
    });

    if (isSuccess) {
      setMarkedStudentList([]);
      fetchClassAttendance(Number(week), calendar?.calendarId);
    }
  }

  /**
  |--------------------------------------------------
  | Increase calendar week only if current week is 
  | less than calendar week
  |--------------------------------------------------
  */
  function increaseWeek() {
    if (Number(week) === Number(calendar?.week)) return;
    navigate(
      `/${schoolId}/classes/${classId}/attendance?week=${
        Number(week) + 1
      }&period=${period}`
    );
  }

  /**
  |--------------------------------------------------
  | decrease calendar week only if current week is
  | greater than 1
  |--------------------------------------------------
  */
  function decreaseWeek() {
    if (Number(week) <= 1) return;
    navigate(
      `/${schoolId}/classes/${classId}/attendance?week=${
        Number(week) - 1
      }&period=${period}`
    );
  }

  function handleSelectWeek(params) {
    navigate(
      `/${schoolId}/classes/${classId}/attendance?week=${params?.currentTarget.value}&period=${period}`
    );
  }

  function handleSelectPeriod(params) {
    setMarkedStudentList([]);
    navigate(
      `/${schoolId}/classes/${classId}/attendance?week=${week}&period=${params}`
    );
  }

  /**
  |--------------------------------------------------
  | get clicked date from resumption date till day clicked
  |--------------------------------------------------
  */
  const getDateFromResumptionDate = useCallback(
    (dayIndex) => {
      if (week === 0) {
        return handleToast("Current calendar week has not started", "warning");
      }

      setMarkedStudentList([]);
      const resumptionDate = new Date(calendar?.resumptionDate);

      /**
      |--------------------------------------------------
      | get total days from calendar week minus the current week
      |--------------------------------------------------
      */
      let totalDaysFromCalendar = (week - 1) * 7;

      /**
      |--------------------------------------------------
      | add dayIndex to totalDaysFromcalendar
      |--------------------------------------------------
      */
      let totalDays = totalDaysFromCalendar + dayIndex;

      /**
      |--------------------------------------------------
      | add totalDays to resumption date
      |--------------------------------------------------
      */
      let selectedDate = resumptionDate.setDate(
        resumptionDate.getDate() + (totalDays - 1)
      );
      /**
      |--------------------------------------------------
      | Update current date state
      |--------------------------------------------------
      */
      setCurrentDate(new Date(selectedDate));
    },
    [calendar, week]
  );

  useEffect(() => {
    if (!currentDate && calendar) {
      const dayName = getDayStringFromDate(new Date());

      let index;
      if (dayName === "monday") index = 1;
      if (dayName === "tuesday") index = 2;
      if (dayName === "wednesday") index = 3;
      if (dayName === "thursday") index = 4;
      if (dayName === "friday") index = 5;

      getDateFromResumptionDate(index);
    }
  }, [currentDate, getDateFromResumptionDate, calendar]);

  /**
  |--------------------------------------------------
  | Select student for attendance row
  |--------------------------------------------------
  */
  function handleRowSelect(studentId) {
    navigate(
      `/${schoolId}/classes/${classId}/attendance?selected=${studentId}&week=${week}&period=${period}`
    );
  }

  function closeSideModal() {
    navigate(
      `/${schoolId}/classes/${classId}/attendance?week=${week}&period=${period}`
    );
  }

  function handleNewSheets() {
    navigate(
      `/${schoolId}/classes/${classId}/attendance?week=${week}&period=${period}&action=new-sheets`
    );
  }

  function getWeeksList(length) {
    if (length) {
      const numbers = Array.from({ length }, (_, i) => ({
        value: i + 1,
        name: `Week ${i + 1}`,
      }));

      return numbers;
    }
  }

  if (attendanceLoading || loadingStudents)
    return <PageLoader loading={attendanceLoading || loadingStudents} />;

  return (
    <div>
      <div className={studentId ? "page" : "full_page"}>
        <div className="mb-5">
          {/**
            |--------------------------------------------------
            | Page title
            |--------------------------------------------------
            */}
          <div className="xs:flex xs:items-center xs:justify-between">
            <div className="flex items-center gap-2">
              <p className="page_title hidden sm:block">
                {formatToStringDate(currentDate, true)}
              </p>
              <p className="page_title sm:hidden">
                {formatDateTwo(currentDate, "/")}
              </p>

              <div className="flex items-center bg-white rounded-md">
                <div
                  className="cursor-pointer py-1 px-1 !h-8 ssm:!h-9 flex justify-center items-center"
                  onClick={decreaseWeek}
                >
                  <AiOutlineLeft />
                </div>

                <SelectInput
                  required
                  value={week}
                  placeholder="week"
                  inputStyle={"text-primary"}
                  className="mb-0 w-20 sm:w-24"
                  handleSelect={handleSelectWeek}
                  options={getWeeksList(calendar?.week)}
                  containerStyle={"!h-8 ssm:!h-9 bg-white border-none"}
                />

                <div
                  className="cursor-pointer py-1 px-1 !h-8 ssm:!h-9 flex justify-center items-center"
                  onClick={increaseWeek}
                >
                  <AiOutlineRight />
                </div>
              </div>
            </div>

            <button
              className="flex items-center justify-center gap-2 bg-primary h-8 xxs:h-8 ssm:h-9 rounded-md text-white px-3 min-w-[100px] disabled:bg-greyX11"
              onClick={handleSubmitAttendance}
              disabled={!markedStudentList?.length}
            >
              {submitting ? (
                <Loader size={20} loading={submitting} color="white" />
              ) : (
                <small>Submit</small>
              )}
            </button>
          </div>
        </div>

        <div className="w-fit">
          <button
            className={`py-1 px-3 text-sm cursor-pointer h-8 xxs:h-8 ssm:h-9 text-independence ${
              !action &&
              period === "morning" &&
              "text-primary bg-white font-semibold"
            }`}
            onClick={() => handleSelectPeriod("morning")}
          >
            Morning
          </button>
          <button
            className={`py-1 px-3 text-sm cursor-pointer h-8 xxs:h-8 ssm:h-9 text-independence ${
              !action &&
              period === "evening" &&
              "text-primary bg-white font-semibold"
            }`}
            onClick={() => handleSelectPeriod("evening")}
          >
            Evening
          </button>
          <button
            className={`py-1 px-3 text-sm cursor-pointer h-8 xxs:h-8 ssm:h-9 text-independence ${
              action === "new-sheets" && "text-primary bg-white font-semibold"
            }`}
            onClick={handleNewSheets}
          >
            New Sheets
          </button>
        </div>
        <div className="main_content">
          <AttendanceOverview />

          <AttendanceTable
            students={students}
            studentId={studentId}
            currentDate={currentDate}
            loading={attendanceLoading}
            attendanceSheet={attendanceSheet}
            handleRowSelect={handleRowSelect}
            markedStudentList={markedStudentList}
            selectedPeriod={capitalizeString(period)}
            setMarkedStudentList={setMarkedStudentList}
            getDateFromResumptionDate={getDateFromResumptionDate}
          />
        </div>
      </div>

      <SideModal
        isVisible={studentId || action}
        closeSideModal={closeSideModal}
      >
        {studentId ? <StudentAttendance studentId={studentId} /> : null}

        {action === "new-sheets" ? (
          <NewAttendanceSheets
            week={week}
            students={students}
            sheets={attendanceSheet}
            currentDate={currentDate}
            closeSideModal={closeSideModal}
            checkFutureDate={checkFutureDate}
            fetchClassAttendance={fetchClassAttendance}
          />
        ) : null}
      </SideModal>
    </div>
  );
};
export default Attendance;
