import React, { useEffect, useState } from "react";

import TableInput from "../../form/TableInput";

export default function PreviewStudentsTable({ data, payload, setPayload }) {
  const [hover, setHover] = useState({ key: null, index: -1 });

  function handleUpdatePayload(value, key, index) {
    const foundItem = payload[index];

    if (foundItem) {
      foundItem[key] = value;
      payload[index] = foundItem;

      setPayload([...payload]);
    }
  }

  function handleReset(key, index) {
    const item = data?.[index];
    const foundItem = payload?.[index];

    if (item && foundItem) {
      foundItem[key] = item?.[key];
      payload[index] = foundItem;

      setPayload([...payload]);
    }
  }

  useEffect(() => {
    if (data?.length > 0) {
      const newArray = JSON.parse(JSON.stringify(data));

      setPayload(newArray);
    }
  }, [data, setPayload]);

  return (
    <div className="overflow-auto max-w-[90vw] min-h-[500px] px-3">
      <div className="min-w-[1300px] border-l">
        {/* table header */}
        <div className="grid grid-cols-10 border-b border-t">
          <div className="border-r py-2 px-1">
            <h5 className="font-semibold text-sm">First Name</h5>
          </div>

          <div className="border-r py-2 px-1">
            <h5 className="font-semibold text-sm">Last Name</h5>
          </div>

          <div className="border-r py-2 px-1">
            <h5 className="font-semibold text-sm">Middle Name</h5>
          </div>

          <div className="border-r py-2 px-1">
            <h5 className="font-semibold text-sm">D.O.B</h5>
          </div>

          <div className="py-2 px-1 border-r">
            <h5 className="font-semibold text-sm">Gender</h5>
          </div>

          <div className="py-2 px-1 border-r">
            <h5 className="font-semibold text-sm">Guardian Last Name</h5>
          </div>

          <div className="py-2 px-1 border-r">
            <h5 className="font-semibold text-sm">Guardian Phone No</h5>
          </div>

          <div className="py-2 px-1 border-r">
            <h5 className="font-semibold text-sm">Occupation</h5>
          </div>

          <div className="py-2 px-1 border-r col-span-2">
            <h5 className="font-semibold text-sm">Address</h5>
          </div>
        </div>

        {/* Table body */}
        {payload?.map((student, i) => (
          <div className={`grid grid-cols-10 border-b`} key={i}>
            <div className="border-r py-1 px-1 flex items-center">
              <TableInput
                index={i}
                data={student}
                hover={hover}
                setHover={setHover}
                dataKey={"firstName"}
                handleReset={handleReset}
                handleUpdatePayload={handleUpdatePayload}
              />
            </div>

            <div className="border-r py-1 px-1 flex items-center">
              <TableInput
                index={i}
                data={student}
                hover={hover}
                dataKey={"lastName"}
                setHover={setHover}
                handleReset={handleReset}
                handleUpdatePayload={handleUpdatePayload}
              />
            </div>

            <div className="border-r py-1 px-1 flex items-center">
              <TableInput
                index={i}
                data={student}
                hover={hover}
                dataKey={"middleName"}
                setHover={setHover}
                handleReset={handleReset}
                handleUpdatePayload={handleUpdatePayload}
              />
            </div>

            <div className="border-r py-1 px-1 flex items-center">
              <TableInput
                index={i}
                data={student}
                hover={hover}
                dataKey={"dob"}
                setHover={setHover}
                handleReset={handleReset}
                handleUpdatePayload={handleUpdatePayload}
              />
            </div>

            <div className="border-r py-1 px-1 flex items-center">
              <TableInput
                index={i}
                data={student}
                hover={hover}
                dataKey={"gender"}
                setHover={setHover}
                handleReset={handleReset}
                handleUpdatePayload={handleUpdatePayload}
              />
            </div>

            <div className="border-r py-1 px-1 flex items-center">
              <TableInput
                index={i}
                data={student}
                hover={hover}
                dataKey={"name"}
                setHover={setHover}
                handleReset={handleReset}
                handleUpdatePayload={handleUpdatePayload}
              />
            </div>

            <div className="border-r py-1 px-1 flex items-center">
              <TableInput
                index={i}
                data={student}
                hover={hover}
                dataKey={"phone"}
                setHover={setHover}
                handleReset={handleReset}
                handleUpdatePayload={handleUpdatePayload}
              />
            </div>

            <div className="border-r py-1 px-1 flex items-center">
              <TableInput
                index={i}
                data={student}
                hover={hover}
                setHover={setHover}
                dataKey={"occupation"}
                handleReset={handleReset}
                handleUpdatePayload={handleUpdatePayload}
              />
            </div>

            <div className="border-r py-1 px-1 flex items-center col-span-2">
              <TableInput
                index={i}
                hover={hover}
                data={student}
                dataKey={"address"}
                setHover={setHover}
                handleReset={handleReset}
                handleUpdatePayload={handleUpdatePayload}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
