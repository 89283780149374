import { useCallback, useState } from "react";

import client from "../client";
import { handleToast } from "../../utils/functions";
import handleApiError from "../../hooks/handleApiError";

export default function useIncreaseStudentThreshold(schoolId) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const request = useCallback(
    async (payload) => {
      try {
        setLoading(true);
        const response = await client.post(
          `/school/${schoolId}/billing/increase-threshold`,
          payload
        );

        setData(response.data?.data);

        setLoading(false);
        handleToast(response.data?.message);
        return true;
      } catch (error) {
        setLoading(false);
        handleApiError(error);
        return false;
      }
    },
    [schoolId]
  );

  return { request, loading, data };
}
